/* eslint consistent-return: 0 */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AttachmentIcon from '@mui/icons-material/Attachment';
import MDButton from 'components/MDButton';
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  InputLabel,
  ErrorText,
} from './file-upload.styles';
import imgWord from './img/word.png';
import imgPdf from './img/pdf.png';
import imgExcel from './img/excel.png';
import imgGeneric from './img/document.png';

const KILO_BYTES_PER_BYTE = 1000;
const MEGA_BYTES_PER_BYTE = 1000 * 1000;
const DEFAULT_MAX_FILE_SIZE = 5; //in MB
const DEFAULT_BUTTON_TEXT = null;
const DEFAULT_TEXT_DRAG = 'Drag and drop your files anywhere or';
const DEFAULT_TEXT_LIST_FILE = 'To Upload';
const DEFAULT_ERROR_SIZE = (mb) => `Wrong dimension. Max ${mb} Mb.`;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const isImageFile = (file) => file.type.split('/')[0] === 'image';
const isPdfFile = (file) => file.type.split('/').slice(-1)[0] === 'pdf';
const isWordFile = (file) =>
  ['msword', 'vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(
    file.type.split('/').slice(-1)[0]
  );
const isExcelFile = (file) =>
  ['vnd.ms-excel', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
    file.type.split('/').slice(-1)[0]
  );
const isGenericFile = (file) =>
  !(isImageFile(file) || isPdfFile(file) || isWordFile(file) || isExcelFile(file));

/**
 * File Upload for react
 * Based on https://dev.to/chandrapantachhetri/responsive-react-file-upload-component-with-drag-and-drop-4ef8
 * Heavy modified by @fefoweb - Stefano Frasca 2022
 * @param label
 * @param updateFilesCb
 * @param maxFileSize
 * @param buttonText
 * @param dragText
 * @param listText
 * @param register
 * @param useName
 * @param otherProps
 * @returns {JSX.Element}
 * @constructor
 */
const RHFileUpload = ({
  label,
  updateFilesCb,
  maxFileSize,
  buttonText,
  dragText,
  listText,
  register,
  useName,
  small,
  ...otherProps
}) => {
  const labelButton =
    null === buttonText ? `Upload ${otherProps.multiple ? 'files' : 'a file'}` : buttonText;
  const [files, setFiles] = useState({});
  const [error, setError] = useState('');
  const { ref, ...fields } = register(useName);
  const inputRef = useRef();
  //if file is yet UPLOADED...
  const nameFile =
    !!otherProps.urlFile &&
    null !== otherProps.urlFile &&
    'string' === typeof otherProps.urlFile &&
    'null' !== otherProps.urlFile.toLowerCase()
      ? otherProps.urlFile.split('/').slice(-1)
      : '';
  const withFileUrl =
    !!otherProps.urlFile && 'string' === typeof otherProps.urlFile && '' !== nameFile;

  const handleUploadBtnClick = () => {
    inputRef.current?.click();
  };

  const addNewFiles = (newFiles) => {
    const fileAdded = {};
    Object.keys(newFiles).forEach((key) => {
      const file = newFiles[key];
      if (file.size <= maxFileSize * MEGA_BYTES_PER_BYTE) {
        setError('');
        if (!otherProps.multiple) {
          fileAdded[0] = file;
          return;
        }
        fileAdded[file.name] = file;
      } else {
        setError(DEFAULT_ERROR_SIZE(maxFileSize));
      }
    });
    return { ...fileAdded };
  };

  const callUpdateFilesCb = (oFiles) => {
    const filesAsArray = convertNestedObjectToArray(oFiles);
    //console.log('---- files as array', filesAsArray);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      const updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    } else {
      console.error(`Error uploading new file: ${newFiles}`);
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  /**** TODO: check with multiple files.... ***/
  const deleteFileFromState = () => {
    setFiles({});
    callUpdateFilesCb({});
  };

  const openFile = (url) => {
    !!url && window.open(url);
  };

  return (
    <>
      {withFileUrl && (
        <MDButton
          sx={{ m: 1 }}
          variant="gradient"
          color="success"
          onClick={() => openFile(otherProps.urlFile)}
        >
          <AttachmentIcon /> {nameFile}
        </MDButton>
      )}
      {withFileUrl && !otherProps.disabled && (
        <MDButton
          sx={{ m: 1 }}
          variant="gradient"
          color="error"
          onClick={() => deleteFileFromState(otherProps.urlFile)}
        >
          <DeleteOutlineIcon />
        </MDButton>
      )}
      {!otherProps.disabled && !withFileUrl && (
        <FileUploadContainer>
          <InputLabel>{label}</InputLabel>
          <DragDropText>{dragText}</DragDropText>
          {error && <ErrorText>{error}</ErrorText>}
          <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
            <CloudUploadIcon />
            <span>{labelButton}</span>
          </UploadFileBtn>
          <FormField
            type="file"
            {...fields}
            ref={(instance) => {
              ref(instance); // RHF wants a reference to this input
              inputRef.current = instance; // We also need it to manipulate the element
            }}
            onChange={handleNewFileUpload}
            title=""
            value=""
            {...otherProps}
          />
        </FileUploadContainer>
      )}
      {!withFileUrl && (
        <FilePreviewContainer>
          <span>{listText}</span>
          <PreviewList>
            {Object.keys(files).map((fileName, index) => {
              const file = files[fileName];
              return (
                <PreviewContainer key={fileName}>
                  <div>
                    {isImageFile(file) && (
                      <ImagePreview
                        isIcon={false}
                        src={URL.createObjectURL(file)}
                        alt={`file preview ${index}`}
                        isSmall={small}
                      />
                    )}
                    {isWordFile(file) && (
                      <ImagePreview
                        isIcon
                        src={imgWord}
                        alt={`Word file: ${file.name}`}
                        isSmall={small}
                      />
                    )}
                    {isPdfFile(file) && (
                      <ImagePreview
                        isIcon
                        src={imgPdf}
                        alt={`Pdf file: ${file.name}`}
                        isSmall={small}
                      />
                    )}
                    {isExcelFile(file) && (
                      <ImagePreview
                        isIcon
                        src={imgExcel}
                        alt={`Excel file: ${file.name}`}
                        isSmall={small}
                      />
                    )}
                    {isGenericFile(file) && (
                      <ImagePreview
                        isIcon
                        src={imgGeneric}
                        alt={`Generic file: ${file.name}`}
                        isSmall={small}
                      />
                    )}
                    <FileMetaData
                      isImageFile={
                        isImageFile(file) ||
                        isPdfFile(file) ||
                        isWordFile(file) ||
                        isExcelFile(file)
                      }
                    >
                      <span>{file.name}</span>
                      <aside>
                        <span>{convertBytesToKB(file.size)} kb</span>
                        <DeleteOutlineIcon onClick={() => removeFile(fileName)} />
                      </aside>
                    </FileMetaData>
                  </div>
                </PreviewContainer>
              );
            })}
          </PreviewList>
        </FilePreviewContainer>
      )}
    </>
  );
};

// Setting default values for the props of MDAlert
RHFileUpload.defaultProps = {
  multiple: false,
  maxFileSize: DEFAULT_MAX_FILE_SIZE,
  buttonText: DEFAULT_BUTTON_TEXT,
  dragText: DEFAULT_TEXT_DRAG,
  listText: DEFAULT_TEXT_LIST_FILE,
  small: false,
};
// Typechecking props of the MDAlert
RHFileUpload.propTypes = {
  small: PropTypes.bool,
  register: PropTypes.func.isRequired,
  useName: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  label: PropTypes.string.isRequired,
  updateFilesCb: PropTypes.func.isRequired,
  maxFileSize: PropTypes.number,
  buttonText: PropTypes.string,
  dragText: PropTypes.string,
  listText: PropTypes.string,
};

export default RHFileUpload;
