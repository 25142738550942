import PropTypes from 'prop-types';

import MDBox from '../MDBox';
import MDTypography from '../MDTypography';
import MDButton from '../MDButton';
import { AGGIORNA } from '../../context/constants';

export const SnackbarEdit = ({
  action = 'edit',
  overrideText,
  callback = () => {},
  callbackClose,
}) => {
  const azioneDaEseguire = AGGIORNA === action ? 'editare' : 'eliminare';
  return (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        {overrideText === '' && (
          <MDTypography display="block" variant="caption" fontWeight="medium">
            Stai per {azioneDaEseguire} l&apos;elemento. Vuoi continuare?
          </MDTypography>
        )}
        {overrideText !== '' && (
          <MDTypography display="block" variant="caption" fontWeight="medium">
            <pre style={{ fontFamily: 'inherit' }}>{overrideText}</pre>
          </MDTypography>
        )}
        <>
          <MDButton
            variant="gradient"
            color="success"
            onClick={() => {
              callback(action);
            }}
            sx={{ m: 1 }}
          >
            SI
          </MDButton>
          <MDButton sx={{ m: 1 }} variant="gradient" color="error" onClick={callbackClose}>
            NO
          </MDButton>
        </>
      </MDBox>
    </MDBox>
  );
};

SnackbarEdit.propTypes = {
  action: PropTypes.string,
  callback: PropTypes.func,
  overrideText: PropTypes.string,
  callbackClose: PropTypes.func,
};
SnackbarEdit.defaultProps = {
  action: AGGIORNA,
  overrideText: '',
  callback: () => {},
  callbackClose: () => {},
};
