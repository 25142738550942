/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import PropTypes from 'prop-types';
import { getLookupSelectValueApiFromKeyDb } from '../../reducer/api/mappingApi';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  label: {
    position: {
      top: '-30px',
      left: '-8px',
    },
  },
  helper: {
    color: 'white',
    fontSize: '.8rem',
  },
});

export default function MDSelect({
  staticOptions,
  label,
  onChange,
  callingApi,
  formName = 'item',
  value = {},
  withLabel,
  withHelper,
}) {
  const classes = useStyles();
  const renderItem = (options) => {
    return (
      !!options &&
      options.length > 0 &&
      options.map((el) => (
        <MenuItem
          value={{
            id: el.id,
            label: el.label,
          }}
          key={`${formName}_${el.id}`}
        >
          {el.label}
        </MenuItem>
      ))
    );
  };

  const [withData, setWithData] = useState(true);
  const [autocompleteValue, setAutocompleteValue] = useState(null);

  useEffect(() => {
    //TODO DA OTTIMIZZARE.....
    const valueSelect = value || '';
    if (null !== valueSelect && '' !== valueSelect) {
      const $fnCallback = getLookupSelectValueApiFromKeyDb(formName);

      setWithData(false);
      $fnCallback(valueSelect).then((data) => {
        if (!!data.id) {
          setWithData(true);
          setAutocompleteValue(data);
        }
      });
    } else {
      setAutocompleteValue('');
    }
  }, [value]);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const data = await callingApi();

      if (active) {
        const res = [...data];
        setOptions(res);
        setLoading(!res.length);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  /*useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);*/

  const onChangeSelected = (evt) => {
    if (onChange !== null && typeof onChange === 'function') {
      return onChange(evt.target.value);
    }
    return '';
  };

  return (
    <FormControl fullWidth>
      {!!withLabel && <InputLabel key={`${formName}_inputlabel`}>{label}</InputLabel>}
      {!withData && (
        <CircularProgress key={`loading_auto_${formName}`} color="secondary" size={20} />
      )}
      {!!withData && !loading && (
        <Select
          fullWidth
          onChange={onChangeSelected}
          value={autocompleteValue ?? ''}
          classes={{
            option: classes.option,
            label: classes.label,
          }}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          renderValue={(opt) => {
            return !!opt.id ? `${opt.label}` : 'Scegli il valore';
          }}
          sx={{
            color: 'success.main',
            height: '3em',
          }}
        >
          {renderItem(options)}
        </Select>
      )}
      {!!withHelper && (
        <FormHelperText classes={{ root: classes.helper }} key={`${formName}_helpeText`}>
          {label}
        </FormHelperText>
      )}
    </FormControl>
  );
}

MDSelect.defaultProps = {
  formName: 'item',
  staticOptions: [],
  callingApi: () => {
    [
      {
        id: 1,
        label: 'text',
      },
      {
        id: 2,
        label: 'prova',
      },
    ];
  },
  withLabel: true,
  withHelper: false,
};

MDSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  callingApi: PropTypes.func,
  staticOptions: PropTypes.any,
  formName: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  withLabel: PropTypes.bool,
  withHelper: PropTypes.bool,
};
