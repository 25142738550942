import {
  getApparecchiatureSelectValue,
  getAttivitaSelectValue,
  getCertificazioniSelectValue,
  getClientsSelectValue,
  getCostruttoriSelectValue,
  getMaterialiSelectValue,
  getModelliSelectValue,
  getPartiSelectValue,
  getAppaltatoriSelectValue,
  getTipiDiLavoriSelectValue,
  insertDati,
  updateCorrelati,
  updateDati,
  updateIntervento,
} from 'api/lookup';
import { AGGIORNA } from '../../context/constants';

export const getLookupSelectValueApiFromKeyDb = (key) => {
  const mapping = {
    fk_id_cliente: getClientsSelectValue,
    fk_id_richiedente: getAppaltatoriSelectValue,
    fk_id_apparecchiatura: getApparecchiatureSelectValue,
    fk_id_tipoCertificazione: getCertificazioniSelectValue,
    fk_id_costruttore: getCostruttoriSelectValue,
    fk_id_materiale: getMaterialiSelectValue,
    fk_id_modello: getModelliSelectValue,
    fk_id_tipoLavoro: getTipiDiLavoriSelectValue,
    fk_id_attivita: getAttivitaSelectValue,
    fk_id_parte: getPartiSelectValue,
  };

  return (
    mapping[key] ||
    (() => {
      console.warn(`No mapping lookup value for key "${key}"`);
    })
  );
};

export const getUpdateTechInterventionByTab = (tab, actionDati) => {
  const mapping = {
    0: updateIntervento,
    1: updateCorrelati,
    2: actionDati === AGGIORNA ? updateDati : insertDati,
  };

  return (
    mapping[tab] ||
    (() => {
      console.warn(`No mapping update tech intervention function for tab "${tab}"`);
    })
  );
};
