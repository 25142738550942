/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Skeleton by: www.creative-tim.com

Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import borders from 'assets/theme-dark/base/borders';
import boxShadows from 'assets/theme-dark/base/boxShadows';

const { borderRadius } = borders;
const { xxl } = boxShadows;

export default {
  styleOverrides: {
    paper: {
      borderRadius: borderRadius.lg,
      boxShadow: xxl,
    },

    paperFullScreen: {
      borderRadius: 0,
    },
  },
};
