/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Skeleton by: www.creative-tim.com

Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  defaultProps: {
    disableRipple: false,
  },
};
