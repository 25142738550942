import PropTypes from 'prop-types';
import MDBox from '../MDBox';
import MDTypography from '../MDTypography';
import MDProgress from '../MDProgress';

const CreditProgress = ({ color, value, text }) => (
  <>
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="h4" color="warning" fontWeight="medium">
        {text.title}
      </MDTypography>
      <MDTypography
        variant="caption"
        color="text"
        fontWeight="medium"
        sx={{ marginLeft: '0.8rem' }}
      >
        {text.subtitle}
      </MDTypography>
    </MDBox>
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  </>
);

// Setting default values for the props of MDAlert
CreditProgress.defaultProps = {
  color: 'info',
  value: 100,
  text: {},
};

// Typechecking props of the MDAlert
CreditProgress.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  value: PropTypes.number,
  text: PropTypes.any,
};

export default CreditProgress;
