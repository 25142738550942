/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Skeleton by: www.creative-tim.com

Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';
import { ROUTE_CREDITS } from 'context/constants';

// @mui material components
import Container from '@mui/material/Container';
import Icon from '@mui/material/Icon';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React base styles
import typography from 'assets/theme/base/typography';

function Footer({ light, position }) {
  const { size } = typography;

  return (
    <MDBox position={position} width="100%" bottom={0} py={4}>
      <Container>
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: 'column', lg: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          px={1.5}
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color={light ? 'white' : 'text'}
            fontSize={size.sm}
          >
            &copy; {new Date().getFullYear()}, made with
            <MDBox fontSize={size.md} color={light ? 'white' : 'dark'} mb={-0.5} mx={0.25}>
              <Icon color="inherit" fontSize="inherit">
                favorite
              </Icon>
            </MDBox>
            by
            <MuiLink href="https://www.stefanofrasca.com/" target="_blank">
              <MDTypography variant="button" fontWeight="medium" color={light ? 'white' : 'dark'}>
                &nbsp;Stefano Frasca&nbsp;
              </MDTypography>
            </MuiLink>
            for usable management systems&nbsp;
          </MDBox>
          <MDBox
            component="ul"
            sx={({ breakpoints }) => ({
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center',
              listStyle: 'none',
              mt: 3,
              mb: 0,
              p: 0,

              [breakpoints.up('lg')]: {
                mt: 0,
              },
            })}
          >
            {/*<MDBox component="li" px={2} lineHeight={1}>
              <Link href="#web" target="_blank">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color={light ? 'white' : 'dark'}
                >
                  Frigoelettrica on web
                </MDTypography>
              </Link>
            </MDBox> */}
            <MDBox component="li" pl={2} lineHeight={1}>
              <Link to={ROUTE_CREDITS}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color={light ? 'white' : 'dark'}
                >
                  Guide
                </MDTypography>
              </Link>
            </MDBox>
          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  );
}

// Setting default props for the Footer
Footer.defaultProps = {
  light: false,
  position: 'absolute',
};

// Typechecking props for the Footer
Footer.propTypes = {
  light: PropTypes.bool,
  position: PropTypes.string,
};

export default Footer;
