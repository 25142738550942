/* eslint-disable no-return-assign */
/**
=========================================================
* Soft UI Dashboard React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import Grid from '@mui/material/Grid';
import WifiFindIcon from '@mui/icons-material/WifiFind';
import { Backdrop, CircularProgress } from '@mui/material';

// Soft UI Dashboard React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDSnackbar from 'components/MDSnackbar';
import { SnackbarEdit } from 'components/SnackbarEdit';
import MDButton from 'components/MDButton';

// Data
import TechInterventionTable from 'reducer/TechInterventionTable';
import { deleteIntervento } from 'api/lookup';
import { debounce, isEmpty, isUuid, navigateToRoute } from 'lib/utils';
import { AGGIORNA, RIMUOVI, ROUTE_TI_VIEW } from 'context/constants';
import MDAlert from '../../../../components/MDAlert';

function ResultPanel({ searchParams, findApi, clear }) {
  const managedAction = [RIMUOVI, AGGIORNA];
  const navigate = useNavigate();
  // reload table
  const [updateTable, setUpdateTable] = useState('initial'); //setUpdateTable
  const [, setElements] = useState([]); //elementi totali provenienti dalla api in DataTable  //elements

  const [search, setSearch] = useState(searchParams);
  const [searchData, setSearchData] = useState({}); //dati provenienti dalla ricerca

  const [idElement, setIdElement] = useState(null);
  const [action, setAction] = useState(RIMUOVI);
  const { enqueueSnackbar } = useSnackbar();
  // STATE FOR LOADING
  const [openBackdrop, setOpenBackdrop] = useState(false);
  // OPEN SNACKBAR
  const [openSnackbar, setOpenSnackbar] = useState(false);

  let isSubscribed = false;
  const mountedRef = useRef(true);

  /****** FINDING METHODS..... ******/
  const onChange = (params) => {
    findApi(params)
      .then((data) => {
        if (isSubscribed) {
          setOpenBackdrop(false);
          setSearchData(data);
          setUpdateTable(`change-filter-${+new Date()}`);
        }
      })
      .catch((err) => {
        if (isSubscribed) {
          setOpenBackdrop(false);
          console.error(err);
          enqueueSnackbar(`La ricerca è andata in errore! Check console.`, {
            variant: 'error',
          });
        }
      });
  };
  const debounceOnChange = useCallback(debounce(onChange, 400), []);

  const clearSearch = () => {
    setSearchData({});
    setUpdateTable(`change-filter-${+new Date()}`);
  };

  useEffect(() => {
    setSearch(searchParams);
  }, [searchParams]);

  useEffect(() => {
    // clean up controller
    isSubscribed = true;
    const value2 = Object.values(search).map((el) => !isEmpty(el));
    const existParametersSearch = value2.some((el) => !!el);
    if (!existParametersSearch) {
      clearSearch();
      return false;
    }
    setOpenBackdrop(true);
    debounceOnChange(search);

    return () => {
      isSubscribed = false;
      mountedRef.current = false;
    };
  }, [search, mountedRef]);

  /**** SEARCH *****/
  const startSearch = () => {
    const promise = new Promise((resolve, reject) => {
      !!searchData && searchData.result && resolve(searchData.result);
      !searchData || (searchData.result.length < 1 && reject(new Error('No Data!'), 500));
    });

    return promise;
  };

  /***** CRUD ****/
  const deleteAction = (id) => {
    setOpenBackdrop(true);
    deleteIntervento(id).then((data) => {
      if (!!data.result) {
        // loading false
        setOpenBackdrop(false);
        // refresh table
        setUpdateTable(`delete-${id}-${+new Date()}`);

        enqueueSnackbar(`Intervento [${id}] cancellato!`, {
          variant: 'success',
        });
      } else {
        console.warn(`Errore in deleteIntervento: ${data.message}`);
        enqueueSnackbar(`Problemi in cancellazione! Check console.`, { variant: 'error' });
      }
    });
  };

  /**** SNACKBAR UI ****/
  const openSnackbarAction = (id, toAction) => {
    !!id && setIdElement(id);
    !!toAction && setAction(toAction);

    return setOpenSnackbar(true);
  };
  const closeSnackbarAction = () => {
    setIdElement(null);
    return setOpenSnackbar(false);
  };
  const performAction = (toAction) => {
    if (!managedAction.includes(toAction)) {
      return false;
    }
    //console.log('------ > sto performando azione %s su elemento %s', toAction, idElement);
    if (toAction === RIMUOVI) {
      deleteAction(idElement);
    }
    /*if (toAction === AGGIORNA) {
      updateAction(idElement);
    }*/
    return setOpenSnackbar(false);
  };

  const gotoTechIntervention = (id, toAction) => {
    const basePath = `${ROUTE_TI_VIEW}/${id}`;
    toAction === AGGIORNA
      ? navigateToRoute(navigate, `${basePath}/edit?from=search`)
      : navigateToRoute(navigate, `${basePath}/show?from=search`);
  };

  const renderSnackBar = (
    <MDSnackbar
      color="error"
      icon="delete"
      title="Attenzione!"
      content={
        <SnackbarEdit
          action={action}
          overrideText={`Stai per eliminare l'intervento tecnico [${idElement}].\n\rQuesto azione IRREVERSIBILE comporta anche\n\rla cancellazione di TUTTI i dati associati, compresi gli allegati.\n\rVuoi continuare?`}
          callback={performAction}
          callbackClose={closeSnackbarAction}
        />
      }
      open={openSnackbar}
      onClose={closeSnackbarAction}
      close={closeSnackbarAction}
      bgWhite
    />
  );

  const parseQuery = (res) => {
    const { query } = res;

    if (!!query && !!query.full) {
      return <strong>Per termine: {query.full}</strong>;
    }
    const queryString =
      !!query &&
      Object.entries(query)
        .filter(([, value]) => !!value)
        .map(([el, el2]) => {
          if (isUuid(el2)) {
            const value = !!res.result[0][el]?.nome
              ? res.result[0][el]?.nome
              : res.result[0][el]?.ragione_sociale;
            return `${el}: '${value}'`;
          }
          return `${el}: '${el2}'`;
        })
        .join(' - ');
    return queryString;
  };

  return (
    <Card>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="success" />
      </Backdrop>
      {!!searchData && !!searchData?.result && searchData?.result.length >= 1 && (
        <>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <MDBox>
              <MDTypography variant="h6" gutterBottom>
                SEARCH...
              </MDTypography>
              <MDBox display="flex" alignItems="center" lineHeight={0}>
                <Icon
                  sx={{
                    fontWeight: 'bold',
                    color: ({ palette: { info } }) => info.main,
                    mt: -0.5,
                  }}
                >
                  done
                </Icon>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;Risultati: <strong>{searchData['#']}</strong>
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItems="center" lineHeight={0}>
                <WifiFindIcon
                  sx={{
                    fontWeight: 'bold',
                    color: ({ palette: { info } }) => info.main,
                    mt: -0.5,
                  }}
                />
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{parseQuery(searchData)}
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDButton variant="gradient" color="info" fullWidth onClick={clear}>
                  <Icon
                    sx={{
                      fontWeight: 'bold',
                      color: ({ palette: { error } }) => error.dark,
                      mt: -0.5,
                    }}
                  >
                    delete
                  </Icon>
                  PULISCI
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>

          <MDBox>
            <TechInterventionTable
              onEditClb={gotoTechIntervention}
              onShowClb={gotoTechIntervention}
              onDeleteClb={openSnackbarAction}
              reupdate={updateTable}
              onupdate={setElements}
              getData={startSearch}
            />
          </MDBox>
        </>
      )}
      {!!searchData && !!searchData?.result && searchData?.result.length < 1 && (
        <>
          <MDAlert color="warning" dismissible onClick={() => clear()}>
            <MDTypography variant="body2" color="white">
              La tua ricerca non ha prodotto risultati!. Riprova...
            </MDTypography>
          </MDAlert>
        </>
      )}
      <Grid item xs={12} sm={6} lg={3}>
        {renderSnackBar}
      </Grid>
    </Card>
  );
}

ResultPanel.defaultProps = {
  searchParams: {},
  findApi: () => {},
  clear: () => {},
};

ResultPanel.propTypes = {
  searchParams: PropTypes.any,
  findApi: PropTypes.func,
  clear: PropTypes.func,
};

export default ResultPanel;
