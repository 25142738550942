/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ItemIdentified } from 'components/ItemIdentified';
import { formatCurrency, formatDate } from 'lib/utils';
import DataTable from 'components/Tables/DataTable';
import { AGGIORNA, RIMUOVI } from '../context/constants';

function ITAttivitaPartiDataTable({ getData, onEditClb, onDeleteClb, reupdate, onupdate }) {
  const [data, setData] = useState({ rows: [], columns: [], fetching: true });

  const columns = [
    { Header: 'Elemento', accessor: 'element', width: '45%', align: 'left' },
    { Header: 'Codice', accessor: 'codice', align: 'center' },
    { Header: 'Costo', accessor: 'costo', align: 'center' },
    { Header: 'Quantita', accessor: 'qta', align: 'center' },
    { Header: 'Totale', accessor: 'totale', align: 'center' },
    { Header: 'Inserito', accessor: 'inserted', align: 'center' },
    { Header: 'Action', accessor: 'action', align: 'center' },
  ];

  const onEdit = (event) => {
    event.stopPropagation();
    let { id } = event.target.dataset;
    if (!id) {
      id = event.target.parentElement.dataset.id;
    }
    !!onEditClb && 'function' === typeof onEditClb && onEditClb(id, AGGIORNA);
    //console.log('EDITO ELEMENTO:', id);
  };
  const onDelete = (event) => {
    event.stopPropagation();
    let { id } = event.target.dataset;
    if (!id) {
      id = event.target.parentElement.dataset.id;
    }
    !!onDeleteClb && 'function' === typeof onDeleteClb && onDeleteClb(id, RIMUOVI);
    //console.log('RIMUOVO ELEMENTO:', id);
  };

  const decorateFrontend = (dbData, isAttivita) => {
    if (!dbData || dbData.length <= 0) {
      return [];
    }
    const toFe = dbData.map((el) => {
      const typeEl = isAttivita ? el.attivita : el.parte;
      return {
        element: <ItemIdentified id={el.id} name={typeEl.nome} />,
        codice: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={!!typeEl.codice ? typeEl.codice : '-'}
              color={'info'}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        costo: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {formatCurrency(typeEl.costo_unitario)} &euro;
          </MDTypography>
        ),
        qta: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {el.qta}
          </MDTypography>
        ),
        totale: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {formatCurrency(el.totale)} &euro;
          </MDTypography>
        ),
        inserted: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {formatDate(el.created_at)}
          </MDTypography>
        ),
        action: (
          <>
            <MDButton
              sx={{ m: 1 }}
              variant="gradient"
              color="error"
              onClick={onDelete}
              data-id={el.id}
            >
              <DeleteForeverIcon />
            </MDButton>
          </>
        ),
      };
    });

    return toFe;
  };

  useEffect(() => {
    try {
      if (reupdate !== '') {
        getData().then((reqData) => {
          !!onupdate && typeof onupdate === 'function' && onupdate(reqData);
          const isAttivita = typeof reqData[0] !== 'undefined' && !!reqData[0].attivita;
          const mapped = decorateFrontend(reqData, isAttivita);
          setData({
            columns,
            rows: mapped,
            fetching: false,
          });
        });
      }
    } catch (e) {
      console.error(`getData: error on call: ${e.message()}`);
    }
    return () => console.log('AttivitaPartiDataTable unmounting...');
  }, [reupdate]);

  return (
    <DataTable
      table={{ columns: data.columns, rows: data.rows }}
      isSorted
      canSearch={false}
      entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15] }}
      showTotalEntries
      noEndBorder
      loading={data.rows.length <= 0 && !!data.fetching}
    />
  );
}

ITAttivitaPartiDataTable.defaultProps = {
  getData: () => {},
  onEditClb: () => {},
  onDeleteClb: () => {},
  reupdate: 'initial',
  onupdate: () => {},
};

ITAttivitaPartiDataTable.propTypes = {
  getData: PropTypes.func,
  onEditClb: PropTypes.func,
  onDeleteClb: PropTypes.func,
  reupdate: PropTypes.string,
  onupdate: PropTypes.func,
};

export default ITAttivitaPartiDataTable;
