/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import AddTaskIcon from '@mui/icons-material/AddTask';

import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

import RHDAutocomplete from 'components/RHDynamicAutocomplete';
import {
  deleteInterventoAttivita,
  getAttivitaSelect,
  getInterventoAttivita,
  insertAttivita,
  insertInterventoAttivita,
} from 'api/lookup';
import { useSnackbar } from 'notistack';
import { AGGIORNA, INSERISCI, RIMUOVI, VEDI } from 'context/constants';
import MDSnackbar from 'components/MDSnackbar';
import { SnackbarEdit } from 'components/SnackbarEdit';
import ITAttivitaPartiDataTable from 'reducer/ITAttivitaPartiDataTable';
import { formatCurrency } from '../../../../lib/utils';

export const AttivitaTi = ({ handle, action, techIntervention, displayAction }) => {
  const __SECTION_TITLE = `ATTIVITA DELL'INTERVENTO TECNICO`;

  const emptyData = {
    attivita: null,
    qta: 0,
    fk_id_it: techIntervention,
    tot: 0,
  };
  const [choiceActivity, setChoiceActivity] = useState(false);
  const [data, setData] = useState(emptyData);
  const { enqueueSnackbar } = useSnackbar();

  // MANAGE UI
  const [idElement, setIdElement] = useState(null);
  const [actionATI, setActionATI] = useState(INSERISCI);
  // reload table
  const [updateTable, setUpdateTable] = useState('initial');
  const [, setElements] = useState([]); //elementi totali provenienti dalla api in DataTable
  // OPEN SNACKBAR
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleChoiceAttivita = (choices) => {
    if (!!choices) {
      setData({
        ...data,
        attivita: choices,
      });
      setChoiceActivity(true);
    }
  };

  const handleQuantity = (e) => {
    return setData({
      ...data,
      qta: +e.target.value,
      tot: +e.target.value * +data.attivita.costo_unitario,
    });
  };

  /***** CRUD *****/
  const deleteAction = (id) => {
    //setOpenBackdrop(true);
    deleteInterventoAttivita(id).then((deleted) => {
      if (!!deleted.result) {
        // loading false
        //setOpenBackdrop(false);
        // refresh table
        setUpdateTable(`delete-${id}-${+new Date()}`);

        enqueueSnackbar(`Elemento [${id}] cancellato!`, {
          variant: 'success',
        });
      } else {
        console.warn(`Errore in deleteInterventoAttivita: ${deleted.message}`);
        enqueueSnackbar(`Problemi in cancellazione! Check console.`, { variant: 'error' });
      }
    });
  };
  const savingAttivita = () => {
    const payload = {
      qta: data.qta,
      totale: data.tot,
      fk_id_it: data.fk_id_it,
      fk_id_attivita: data.attivita.id,
    };
    insertInterventoAttivita(payload).then((el) => {
      if (!!el.id) {
        enqueueSnackbar(`Elemento [${el.id}] inserito!`, {
          variant: 'success',
        });
        setChoiceActivity(false);
        setUpdateTable(`insert-${el.id}-${+new Date()}`);
        setData(emptyData);
      } else {
        console.warn(`Errore in insertInterventoAttivita: ${el.message}`);
        enqueueSnackbar(`Problemi in inserimento! Check console.`, { variant: 'error' });
      }
    });
  };

  /*********** GESTIONE ALERT *************/
  const openSnackbarAction = (id, toAction) => {
    if (!id) {
      return setOpenSnackbar(false);
    }
    !!id && setIdElement(id);
    !!toAction && setActionATI(toAction);
    return setOpenSnackbar(true);
  };
  const closeSnackbarAction = () => {
    //setIdElement(null);
    //setAction('');
    return setOpenSnackbar(false);
  };
  const performAction = (toAction) => {
    if (toAction === RIMUOVI) {
      deleteAction(idElement);
    }
    return setOpenSnackbar(false);
  };

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Attenzione!"
      content={
        <SnackbarEdit
          action={actionATI}
          callback={performAction}
          callbackClose={closeSnackbarAction}
        />
      }
      open={openSnackbar}
      onClose={closeSnackbarAction}
      close={closeSnackbarAction}
      bgWhite
    />
  );

  return (
    <Card variant="outlined" style={{ width: '100%' }}>
      <MDBox pt={4} pb={3} px={3} fullWidth>
        <MDTypography variant={'h2'}>{__SECTION_TITLE}</MDTypography>
        <MDBox component="form" role="form">
          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
            {displayAction !== VEDI && (
              <>
                {!choiceActivity && (
                  <Grid item xs={12} md={6}>
                    <RHDAutocomplete
                      callingApi={getAttivitaSelect}
                      insertApi={insertAttivita}
                      fnOnChange={handleChoiceAttivita}
                      control={handle.control}
                      watch={handle.watch}
                      formValue={handle.setValue}
                      defaultDialog={{
                        nome: { type: 'string', maxLength: 80, label: 'Nome' },
                        codice: { type: 'string', maxLength: 20, label: 'Codice' },
                        costo_unitario: { type: 'string', maxLength: 20, label: 'Costo unitario' },
                      }}
                      prepopulateKey={'nome'}
                      formName={'fk_id_attivita'}
                      label={`Aggiungi l'attivita...`}
                      errors={handle.errors}
                      /*rules={{ required: 'Scegli il cliente!' }}*/
                    />
                  </Grid>
                )}
                {!!choiceActivity && (
                  <>
                    <Grid item xs={12} md={4}>
                      <MDTypography variant={'p'}>
                        {data?.attivita?.label} ({data?.attivita?.costo_unitario}€)
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <MDInput
                        key={'att_form_input'}
                        label={'Aggiungi quantità'}
                        onChange={handleQuantity}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <MDTypography variant={'p'}>
                        Tot: {formatCurrency(data?.attivita?.costo_unitario * data?.qta)} &euro;
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        fullWidth
                        onClick={savingAttivita}
                      >
                        <AddTaskIcon />
                      </MDButton>
                    </Grid>
                  </>
                )}
              </>
            )}
            <MDBox pt={3}>
              <ITAttivitaPartiDataTable
                onDeleteClb={
                  displayAction !== VEDI
                    ? openSnackbarAction
                    : () => {
                        enqueueSnackbar(`In modalita visualizzazione non puoi cancellare!`, {
                          variant: 'warning',
                        });
                      }
                }
                reupdate={updateTable}
                onupdate={setElements}
                getData={() => getInterventoAttivita(data.fk_id_it)}
              />
            </MDBox>
          </Grid>
        </MDBox>
      </MDBox>
      <Grid item xs={12} sm={6} lg={3}>
        {renderWarningSB}
      </Grid>
    </Card>
  );
};

AttivitaTi.defaultProps = {
  handle: {
    actionToLookup: () => {},
  },
  displayAction: AGGIORNA,
};

AttivitaTi.propTypes = {
  handle: PropTypes.any,
  techIntervention: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  displayAction: PropTypes.string,
};
