/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAsyncDebounce } from 'react-table';

import MDInput from '../../MDInput';
import MDBox from '../../MDBox';

const TWO_HUNDRED_MS = 200;

export const GlobalFilter = ({ preFilterRow, filter, setFilter }) => {
  const [value, setValue] = useState(filter);
  const onChange = useAsyncDebounce((text) => {
    setFilter(text || undefined);
  }, TWO_HUNDRED_MS);

  return (
    <MDBox width="12rem" ml="auto">
      <MDInput
        placeholder="Search..."
        value={value || ''}
        size="small"
        fullWidth
        onChange={({ currentTarget }) => {
          setValue(currentTarget.value);
          onChange(currentTarget.value);
        }}
      />
    </MDBox>
  );
};

// Setting default values for the props of MDAlert
GlobalFilter.defaultProps = {
  filter: '',
  preFilterRow: [],
};

// Typechecking props of the MDAlert
GlobalFilter.propTypes = {
  preFilterRow: PropTypes.array,
  filter: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
};
/* eslint-enabled no-unused-vars */
