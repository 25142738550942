export const formatDate = (dbDate, slice = false) => {
  const localeDate = new Date(dbDate).toLocaleString();
  return !!slice ? localeDate.split(',')[0] : localeDate;
};

export const dateToYYYYMMDD = (dbDate) => {
  const d = new Date(dbDate);
  return d.toISOString().slice(0, 10);
};

export const labelFormatDate = 'Nella forma DD/MM/YYYY';

export const onClickDate = (evt) => {
  //alert(evt.target.value);
  evt.target.showPicker();
};

export const formatCurrency = (num) => (Math.round(num * 100) / 100).toFixed(2);

export const findElement = (data, key, value, type = 'single') => {
  const aData = data.filter((el) => el[key] === value);
  return type === 'single' ? (aData.length === 1 ? aData[0] : null) : aData;
};

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export const countryToFlag = (isoCode) => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
};

export const goToRoute = (event, navigate) => {
  const { route } = event.target.dataset;
  navigate(route);
};

export const navigateToRoute = (navigate, route) => {
  navigate(route);
};

export const isObject = (_var) => typeof _var === 'object' && !Array.isArray(_var) && _var !== null;

export const isArray = (_var) => Array.isArray(_var);

export const isString = (_var) => typeof _var === 'string' || _var instanceof String;

export const isUuid = (_var) =>
  isString(_var) &&
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(_var);

function emptyObject(obj) {
  return isObject(obj) && Object.keys(obj).length === 0;
}
function emptyString(str) {
  if (!isString(str)) {
    return false;
  }
  let r = false;
  if (!str || !str.trim()) {
    r = true;
  }
  return r;
}
function emptyArray(arr) {
  return Array.isArray(arr) && !arr.length;
}

export const isEmpty = (_var) => {
  return emptyString(_var) || emptyObject(_var) || emptyArray(_var) || !_var;
};

/*** TIMING AND DEBOUNCING ***/
export function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}
