import React from 'react';
import PropTypes from 'prop-types';

import MDBox from 'components/MDBox';

import { INSERISCI } from 'context/constants';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import RHAutocomplete from 'components/RHAutocomplete';
import { findElement } from 'lib/utils';

const optionsType = [
  { label: 'Viewer', id: 2 },
  { label: 'Editor', id: 4 },
  { label: 'Admin', id: 8 },
];

export const UserForm = ({ handle, action }) => {
  const typeId = handle.watch('type') || 0;
  const typeDefault = findElement(optionsType, 'id', typeId);

  return (
    <Card variant="outlined">
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
            <Grid item xs={12} md={6}>
              <MDInput
                type="text"
                label="Nome"
                variant="standard"
                focused={!!handle.watch('nome')}
                fullWidth
                {...handle.register('nome', {
                  required: 'Obbligatorio!',
                  maxLength: {
                    value: 50,
                    message: 'Max. 50 caratteri', // JS only: <p>error message</p> TS only support string
                  },
                })}
                error={!!handle.errors?.nome}
                helperText={handle.errors?.nome?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MDInput
                type="text"
                label="Cognome"
                variant="standard"
                focused={!!handle.watch('cognome')}
                fullWidth
                {...handle.register('cognome', {
                  required: 'Obbligatorio!',
                  maxLength: {
                    value: 50,
                    message: 'Max. 50 caratteri', // JS only: <p>error message</p> TS only support string
                  },
                })}
                error={!!handle.errors?.cognome}
                helperText={handle.errors?.cognome?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MDInput
                type="text"
                label="Email"
                variant="standard"
                focused={!!handle.watch('email')}
                fullWidth
                {...handle.register('email', {
                  required: 'Obbligatorio!',
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: 'Inserire una email valida!', // JS only: <p>error message</p> TS only support string
                  },
                })}
                error={!!handle.errors?.email}
                helperText={handle.errors?.email?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                {...handle.register('password', {
                  required: action === INSERISCI ? 'Obbligatorio!' : false,
                })}
                disabled={!(action === INSERISCI)}
                error={!!handle.errors?.password}
                helperText={handle.errors?.password?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHAutocomplete
                autocompleteOptions={optionsType}
                control={handle.control}
                defaultValue={typeDefault}
                formName={'type'}
                label={'Scegli...'}
                errors={handle.errors}
                rules={{ required: 'Scegli il tipo di utente per continuare!' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handle.handleSubmit(handle.onSubmit)}
              >
                {action === INSERISCI ? 'Inserisci' : 'Aggiorna'}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
};

UserForm.defaultProps = {
  handle: {
    actionToLookup: () => {},
  },
};

UserForm.propTypes = {
  handle: PropTypes.any,
  action: PropTypes.string.isRequired,
  initState: PropTypes.object.isRequired,
};
