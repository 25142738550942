/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Skeleton by: www.creative-tim.com

Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

// Dashboard components
import { getLocalStorage, LS_AUTH, LS_SEARCH_PARAMS, setLocalStorage } from 'lib/storage';
import { ROUTE_DASHBOARD, ROUTE_LOGIN } from 'context/constants';
import { checkAuth, VIEWER } from 'context/auth';
import { getClientsSelect, search } from 'api/lookup';
import MDInput from 'components/MDInput';
import MDSelect from 'components/MDSelect';
import ResultPanel from './components/ResultPane';
import { labelFormatDate, onClickDate } from '../../lib/utils';

function Search() {
  const __AUTH = VIEWER;
  const navigate = useNavigate();
  const token = getLocalStorage(LS_AUTH);
  const searchLS = getLocalStorage(LS_SEARCH_PARAMS);
  const [client, setClient] = useState('');

  const initialSearch = {
    anno: null,
    matricola: null,
    inventario: null,
    tecnicoEsecutore: null,
    cliente: null,
    full: null,
    dataIntervento: null,
  };
  const [searchParams, setSearchParams] = useState(initialSearch);
  const [isSearching, setSearching] = useState(false);

  useEffect(() => {
    if (!token) {
      navigate(ROUTE_LOGIN);
      return false;
    }
    // NOTE: tutte le rotte sotto autenticazione, se di grado troppo alto devono RIDIRIGERE a Dashboard
    if (!!__AUTH && !checkAuth(__AUTH)) {
      navigate(ROUTE_DASHBOARD);
      return false;
    }
    //manage old searches...
    const oldParametersSearch = JSON.parse(searchLS);
    if (!!oldParametersSearch) {
      setSearchParams(oldParametersSearch);
    }
    if (!!oldParametersSearch?.cliente) {
      setClient(oldParametersSearch?.cliente);
    }

    return true;
  }, []);

  useEffect(() => {
    if (!isSearching) {
      setLocalStorage(LS_SEARCH_PARAMS, JSON.stringify({}));
    } else {
      setLocalStorage(LS_SEARCH_PARAMS, JSON.stringify(searchParams));
    }
  }, [searchParams]);

  const clearSearch = () => {
    setClient('');
    setSearchParams({
      ...initialSearch,
    });
    setSearching(false);
  };

  const setSearch = (e) => {
    const ret = setSearchParams({
      ...searchParams,
      [e.target.id]: e.target.value,
    });
    setSearching(true);
    return ret;
    //return setName(e.target.value);
  };

  const handleChange = (value) => {
    setSearchParams({
      ...searchParams,
      cliente: value.id,
    });
    setSearching(true);
    setClient(value.id);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox pt={1} pb={1}>
              <MDInput
                type="text"
                label="Full search"
                variant="standard"
                id={'full'}
                fullWidth
                onChange={setSearch}
                value={searchParams.full ?? ''}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox pt={1} pb={1}>
              <MDInput
                type="text"
                label="Matricola"
                variant="standard"
                id={'matricola'}
                fullWidth
                onChange={setSearch}
                value={searchParams.matricola ?? ''}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox pt={1} pb={1}>
              <MDInput
                type="text"
                label="Inventario"
                variant="standard"
                id={'inventario'}
                fullWidth
                onChange={setSearch}
                value={searchParams.inventario ?? ''}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox pt={1} pb={1}>
              <MDSelect
                onChange={handleChange}
                label={'Cliente'}
                callingApi={getClientsSelect}
                formName={'fk_id_cliente'}
                value={client}
                withLabel={false}
                withHelper
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox pt={1} pb={1}>
              <MDInput
                type="text"
                label="Tecnico"
                variant="standard"
                id={'tecnicoEsecutore'}
                fullWidth
                onChange={setSearch}
                value={searchParams.tecnicoEsecutore ?? ''}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox pt={1} pb={1}>
              <MDInput
                type="text"
                label="Anno"
                variant="standard"
                id={'anno'}
                fullWidth
                onChange={setSearch}
                value={searchParams.anno ?? ''}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox pt={1} pb={1}>
              <MDInput
                type="date"
                label="Data Intervento"
                variant="standard"
                id={'dataIntervento'}
                fullWidth
                value={searchParams.dataIntervento ?? ''}
                onChange={setSearch}
                onClick={onClickDate}
                helperText={labelFormatDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox my={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <ResultPanel searchParams={searchParams} clear={clearSearch} findApi={search} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

/**
 <Grid item xs={12} md={6} lg={4}>
 <MDBox>
 <ReportsLineChart
 color="dark"
 title="Interventi"
 description="Interventi per mese"
 date="just updated"
 chart={intervention}
 />
 </MDBox>
 </Grid>
 */

export default Search;
