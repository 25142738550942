import React from 'react';
import PropTypes from 'prop-types';

import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import { INSERISCI, AGGIORNA } from 'context/constants';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import RHFileUpload from 'components/RHFileUpload';

import { labelFormatDate, onClickDate } from '../../../lib/utils';
import MDAlert from '../../../components/MDAlert';

export const DatiForm = ({ handle, action, displayAction }) => {
  return (
    <Card variant="outlined" style={{ width: '100%' }}>
      <MDBox pt={4} pb={3} px={3}>
        {action !== AGGIORNA && (
          <MDAlert color="info" severity="info" dismissible>
            Attenzione! Devi prima inserire i dati del primo step (e salvare)!
          </MDAlert>
        )}
        <MDBox component="form" role="form">
          {/* CHIAMATA */}
          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
            <Grid item xs={12} md={3}>
              <MDInput
                type="text"
                label="Chiamata n."
                variant="standard"
                focused={!!handle.watch('chiamata_n')}
                fullWidth
                {...handle.register('chiamata_n')}
                error={!!handle.errors?.chiamata_n}
                helperText={handle.errors?.chiamata_n?.message}
                disabled={displayAction === 'show'}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MDInput
                type="date"
                label="Chiamata del"
                variant="standard"
                focused={!!handle.watch('chiamata_del')}
                fullWidth
                {...handle.register('chiamata_del')}
                error={!!handle.errors?.chiamata_del}
                disabled={displayAction === 'show'}
                onClick={onClickDate}
                helperText={
                  !!handle.errors?.chiamata_del
                    ? handle.errors?.chiamata_del?.message
                    : labelFormatDate
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFileUpload
                accept=".pdf,.xls,.xlsx,.doc,.docx,.zip"
                label="Allegato della chiamata"
                multiple={false}
                updateFilesCb={(files) => {
                  handle.setValue('chiamata_file', files);
                }}
                buttonText={'Clicca per caricare'}
                dragText={'Trascina il file oppure'}
                listText={'File(s) caricati'}
                register={handle.register}
                useName={'chiamata_file'}
                small
                disabled={displayAction === 'show'}
                urlFile={handle.watch('chiamata_file')}
              />
            </Grid>
          </Grid>
          {/* PREVENTIVO */}
          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
            <Grid item xs={12} md={3}>
              <MDInput
                type="text"
                label="Preventivo n."
                variant="standard"
                focused={!!handle.watch('preventivo_n')}
                fullWidth
                {...handle.register('preventivo_n')}
                error={!!handle.errors?.preventivo_n}
                helperText={handle.errors?.preventivo_n?.message}
                disabled={displayAction === 'show'}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MDInput
                type="date"
                label="Preventivo del"
                variant="standard"
                focused={!!handle.watch('preventivo_del')}
                fullWidth
                onClick={onClickDate}
                {...handle.register('preventivo_del')}
                error={!!handle.errors?.preventivo_del}
                disabled={displayAction === 'show'}
                helperText={
                  !!handle.errors?.preventivo_del
                    ? handle.errors?.preventivo_del?.message
                    : labelFormatDate
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFileUpload
                accept=".pdf,.xls,.xlsx,.doc,.docx,.zip"
                label="Allegato del preventivo"
                multiple={false}
                updateFilesCb={(files) => {
                  handle.setValue('preventivo_file', files);
                }}
                buttonText={'Clicca per caricare'}
                dragText={'Trascina il file oppure'}
                listText={'File(s) caricati'}
                register={handle.register}
                useName={'preventivo_file'}
                small
                disabled={displayAction === 'show'}
                urlFile={handle.watch('preventivo_file')}
              />
            </Grid>
          </Grid>
          {/* ORDINE CLIENTE */}
          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
            <Grid item xs={12} md={3}>
              <MDInput
                type="text"
                label="Ordine cliente n."
                variant="standard"
                focused={!!handle.watch('ordinecliente_n')}
                fullWidth
                {...handle.register('ordinecliente_n')}
                error={!!handle.errors?.ordinecliente_n}
                disabled={displayAction === 'show'}
                helperText={handle.errors?.ordinecliente_n?.message}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MDInput
                type="date"
                label="Ordine cliente del"
                variant="standard"
                focused={!!handle.watch('ordinecliente_del')}
                fullWidth
                onClick={onClickDate}
                {...handle.register('ordinecliente_del')}
                error={!!handle.errors?.ordinecliente_del}
                disabled={displayAction === 'show'}
                helperText={
                  !!handle.errors?.ordinecliente_del
                    ? handle.errors?.ordinecliente_del?.message
                    : labelFormatDate
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFileUpload
                accept=".pdf,.xls,.xlsx,.doc,.docx,.zip"
                label="Allegato dell'ordine"
                multiple={false}
                updateFilesCb={(files) => {
                  handle.setValue('ordinecliente_file', files);
                }}
                buttonText={'Clicca per caricare'}
                dragText={'Trascina il file oppure'}
                listText={'File(s) caricati'}
                register={handle.register}
                useName={'ordinecliente_file'}
                small
                disabled={displayAction === 'show'}
                urlFile={handle.watch('ordinecliente_file')}
              />
            </Grid>
          </Grid>
          {/* CONTRATTO */}
          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
            <Grid item xs={12} md={3}>
              <MDInput
                type="text"
                label="Contratto n."
                variant="standard"
                focused={!!handle.watch('contratto_n')}
                fullWidth
                {...handle.register('contratto_n')}
                error={!!handle.errors?.contratto_n}
                helperText={handle.errors?.contratto_n?.message}
                disabled={displayAction === 'show'}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MDInput
                type="date"
                label="Contratto del"
                variant="standard"
                focused={!!handle.watch('contratto_del')}
                fullWidth
                onClick={onClickDate}
                {...handle.register('contratto_del')}
                error={!!handle.errors?.contratto_del}
                disabled={displayAction === 'show'}
                helperText={
                  !!handle.errors?.contratto_del
                    ? handle.errors?.contratto_del?.message
                    : labelFormatDate
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFileUpload
                accept=".pdf,.xls,.xlsx,.doc,.docx,.zip"
                label="Allegato contratto"
                multiple={false}
                updateFilesCb={(files) => {
                  handle.setValue('contratto_file', files);
                }}
                buttonText={'Clicca per caricare'}
                dragText={'Trascina il file oppure'}
                listText={'File(s) caricati'}
                register={handle.register}
                useName={'contratto_file'}
                small
                disabled={displayAction === 'show'}
                urlFile={handle.watch('contratto_file')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
            <Grid item xs={12} md={6}>
              &nbsp;
            </Grid>
            <Grid item xs={12} md={6}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handle.handleSubmit(handle.onSubmit)}
                disabled={action !== AGGIORNA || displayAction === 'show'}
              >
                {action === INSERISCI ? 'Inserisci' : 'Aggiorna'}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
};

DatiForm.defaultProps = {
  handle: {
    actionToLookup: () => {},
  },
  displayAction: 'edit',
};

DatiForm.propTypes = {
  handle: PropTypes.any,
  action: PropTypes.string.isRequired,
  displayAction: PropTypes.string,
};
