/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Skeleton by: www.creative-tim.com

Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Backdrop, CircularProgress } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import MDSnackbar from 'components/MDSnackbar';
import UserDataTable from 'reducer/UserDataTable';
import { SnackbarEdit } from 'components/SnackbarEdit';
import { AGGIORNA, INSERISCI, RIMUOVI, ROUTE_DASHBOARD, ROUTE_LOGIN } from 'context/constants';
import { deleteUser, getUsers, insertUser, updateUser } from 'api/lookup';
import { findElement } from 'lib/utils';
import { ADMIN, checkAuth } from 'context/auth';
import { useNavigate } from 'react-router-dom';
import { getLocalStorage, LS_AUTH } from 'lib/storage';

import { UserForm } from './form/user';

function Users() {
  const managedAction = [RIMUOVI, AGGIORNA];
  const __SECTION_TITLE = 'Users';
  const __AUTH = ADMIN;
  const navigate = useNavigate();
  const token = getLocalStorage(LS_AUTH);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!token) {
      navigate(ROUTE_LOGIN);
      return false;
    }
    // NOTE: tutte le rotte sotto autenticazione, se di grado troppo alto devono RIDIRIGERE a Dashboard
    if (!!__AUTH && !checkAuth(__AUTH)) {
      navigate(ROUTE_DASHBOARD);
      return false;
    }
    return true;
  }, []);

  // MANAGE UI
  const [idElement, setIdElement] = useState(null);
  const [action, setAction] = useState(INSERISCI);

  // STATE FOR FORM
  const initUserState = {
    id: null,
    nome: '',
    cognome: '',
    password: '',
    email: '',
    type: null,
  };
  const [user, setUser] = useState(initUserState);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: user,
  });

  // reload table
  const [updateTable, setUpdateTable] = useState('initial');
  const [elements, setElements] = useState([]); //elementi totali provenienti dalla api in DataTable
  // STATE FOR LOADING
  const [openBackdrop, setOpenBackdrop] = useState(false);
  // OPEN SNACKBAR
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const resetFormElement = () => {
    setIdElement(null);
    setUser(initUserState);
    reset(initUserState);
  };
  const resetFormValue = () => {
    resetFormElement();

    setIdElement(null);
  };

  /******* CRUD *****/
  const updateAction = (id) => {
    setAction(AGGIORNA);
    // loading false
    //setOpenBackdrop(false);
    //TODO: verificare qeusto
    const elToEdit = findElement(elements, 'id', id);
    setUser(elToEdit);
    reset(elToEdit);
  };
  const deleteAction = (id) => {
    setOpenBackdrop(true);
    deleteUser(id).then((data) => {
      if (!!data.result) {
        // loading false
        setOpenBackdrop(false);
        // refresh table
        setUpdateTable(`delete-${id}-${+new Date()}`);
        resetFormValue();

        enqueueSnackbar(`Utente [${id}] cancellato`, {
          variant: 'success',
        });
      } else {
        console.warn(`Errore in deleteUser: ${data.message}`);
        enqueueSnackbar(`Problemi in cancellazione! Check console.`, { variant: 'error' });
      }
    });
  };
  const performAction = (toAction) => {
    if (!managedAction.includes(toAction)) {
      return false;
    }
    //console.log('------ > sto performando azione %s su elemento %s', toAction, idElement);
    if (toAction === RIMUOVI) {
      deleteAction(idElement);
    }
    if (toAction === AGGIORNA) {
      updateAction(idElement);
    }
    return setOpenSnackbar(false);
  };

  /***** FORM ******/
  const onSubmit = (formData) => {
    const mappedPayload = JSON.parse(JSON.stringify(formData));
    mappedPayload.type = mappedPayload?.type?.id || mappedPayload?.type || null;
    if (action === AGGIORNA) {
      delete mappedPayload.password;
    }
    // console.log('AL SUBMIT', formData, mappedPayload, action, idElement);

    setOpenBackdrop(true);
    if (action === AGGIORNA) {
      updateUser(idElement, mappedPayload).then((data) => {
        setOpenBackdrop(false);
        if (!!data.id) {
          setUser(mappedPayload);
          // update table
          setUpdateTable(`upd-${data.id}-${+new Date()}`);
          resetFormValue();

          enqueueSnackbar(`Utente ${action === AGGIORNA ? 'aggiornato' : 'inserito'}!`, {
            variant: 'success',
          });
        } else {
          console.warn(`Errore in updateUser: ${data.message}`);
          enqueueSnackbar(
            `Problemi in ${action === AGGIORNA ? 'update' : 'inserimento'}! Check console.`,
            { variant: 'error' }
          );
        }
      });
    } else {
      insertUser(mappedPayload).then((data) => {
        setOpenBackdrop(false);
        if (!!data.id) {
          setUser(mappedPayload);
          // update table
          setUpdateTable(`new-${data.id}-${+new Date()}`);
          resetFormValue();

          enqueueSnackbar(`Utente ${action === AGGIORNA ? 'aggiornato' : 'inserito'}!`, {
            variant: 'success',
          });
        } else {
          console.warn(`Errore in insertUser: ${data.message}`);
          enqueueSnackbar(
            `Problemi in ${action === AGGIORNA ? 'update' : 'inserimento'}! Check console.`,
            { variant: 'error' }
          );
        }
      });
    }
  };

  /*********** GESTIONE ALERT *************/
  const openSnackbarAction = (id, toAction) => {
    !!id && setIdElement(id);
    !!toAction && setAction(toAction);

    return setOpenSnackbar(true);
  };
  const closeSnackbarAction = () => {
    //setIdElement(null);
    //setAction('');
    return setOpenSnackbar(false);
  };

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Attenzione!"
      content={
        <SnackbarEdit
          action={action}
          callback={performAction}
          callbackClose={closeSnackbarAction}
        />
      }
      open={openSnackbar}
      onClose={closeSnackbarAction}
      close={closeSnackbarAction}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openBackdrop}
            >
              <CircularProgress color="success" />
            </Backdrop>
            <Card>
              <MDBox p={2} lineHeight={0}>
                <MDTypography variant="h3">{__SECTION_TITLE}</MDTypography>
              </MDBox>
              <MDBox p={2}>
                <Card variant="outlined">
                  <MDBox
                    mx={2}
                    mt={-2}
                    py={1}
                    px={1}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="md"
                    coloredShadow="warning"
                  >
                    <MDTypography variant="h6" color="white">
                      Attualmente presenti...
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <UserDataTable
                      onDeleteClb={openSnackbarAction}
                      onEditClb={openSnackbarAction}
                      reupdate={updateTable}
                      onupdate={setElements}
                      getData={getUsers}
                    />
                  </MDBox>
                </Card>
                <UserForm
                  id="user-form"
                  action={action}
                  handle={{
                    handleSubmit,
                    register,
                    watch,
                    control,
                    onSubmit,
                    errors,
                  }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          {renderWarningSB}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Users;
