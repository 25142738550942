/**
 =========================================================
 * Material Dashboard 2 React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All of the routes for the Soft UI Dashboard React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Sidenav.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Material Dashboard 2 React layouts
import Dashboard from 'layouts/dashboard';
// import Tables from 'layouts/tables';
// import Billing from 'layouts/billing';
// import Notifications from 'layouts/notifications';
import TechIntervention from 'layouts/techintervention';
import SignIn from 'layouts/authentication/sign-in';

// @mui icons
import Icon from '@mui/material/Icon';
import TableRowsIcon from '@mui/icons-material/TableRows';
import Lookup from 'layouts/lookup';
import Apparecchiature from 'layouts/lookup/page/apparecchiature';
import Certificazioni from 'layouts/lookup/page/certificazioni';
import Richiedenti from 'layouts/lookup/page/richiedenti';
import TipiLavori from 'layouts/lookup/page/tipiLavori';
import Materiali from 'layouts/lookup/page/materiali';
import Modelli from 'layouts/lookup/page/modelli';
import Costruttori from 'layouts/lookup/page/costruttori';
import Attivita from 'layouts/lookup/page/attivita';
import Parti from 'layouts/lookup/page/parti';
import Users from 'layouts/users';
import Clienti from './layouts/lookup/page/clienti';
import Credits from './layouts/credits';
import Search from './layouts/find';
import Log from './layouts/log/log';

import { ADMIN, VIEWER, EDITOR } from './context/auth';
import {
  ROUTE_APPARECCHIATURE,
  ROUTE_ATTIVITA,
  ROUTE_CERTIFICAZIONI,
  ROUTE_CLIENTI,
  ROUTE_COSTRUTTORI,
  ROUTE_CREDITS,
  ROUTE_DASHBOARD,
  ROUTE_LOG,
  ROUTE_LOGIN,
  ROUTE_LOOKUP,
  ROUTE_MATERIALI,
  ROUTE_MODELLI,
  ROUTE_PARTI,
  ROUTE_RICHIEDENTI,
  ROUTE_TI_SEARCH,
  ROUTE_TI_SHOW,
  ROUTE_TI_VIEW,
  ROUTE_TIPILAVORO,
  ROUTE_USERS,
} from './context/constants';

/*
 {
    type: 'collapse',
    name: 'Sign In',
    key: 'sign-in',
    icon: <Icon fontSize="small">login</Icon>,
    route: ROUTE_LOGIN,
    component: <SignIn />,
  },
  {
    type: 'collapse',
    name: 'Billing',
    key: 'billing',
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: '/billing',
    component: <Billing />,
  },
  {
    type: 'collapse',
    name: 'Notifications',
    key: 'notifications',
    icon: <Icon fontSize="small">notifications</Icon>,
    route: '/notifications',
    component: <Notifications />, <Profile />
  },
*/
const routes = [
  {
    type: 'hidden',
    name: 'Sign In',
    key: 'sign-in',
    icon: <Icon fontSize="small">login</Icon>,
    route: ROUTE_LOGIN,
    component: <SignIn />,
  },
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    auth: VIEWER,
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: ROUTE_DASHBOARD,
    component: <Dashboard />,
  },
  {
    type: 'collapse',
    name: 'Utenti',
    key: 'users',
    auth: ADMIN,
    icon: <Icon fontSize="small">person</Icon>,
    route: ROUTE_USERS,
    component: <Users />,
  },
  {
    type: 'collapse',
    name: 'Schede Intervento',
    key: 'ti',
    auth: EDITOR,
    icon: <Icon fontSize="small">table_view</Icon>,
    route: ROUTE_TI_VIEW,
    component: <TechIntervention />,
  },
  {
    type: 'hidden',
    name: 'Show Scheda Intervento',
    key: 'show_tisheet',
    route: ROUTE_TI_SHOW,
    component: <TechIntervention />,
  },
  {
    type: 'collapse',
    name: 'Cerca Intervento',
    key: 'find_sheet',
    auth: VIEWER,
    icon: (
      <Icon fontSize="small" color="primary">
        search
      </Icon>
    ),
    route: ROUTE_TI_SEARCH,
    component: <Search />,
  },
  {
    type: 'divider',
    key: 'menu_divider',
  },
  {
    type: 'title',
    title: 'Lookup',
    key: 'label_lookup',
    textColor: 'grey',
  },
  {
    type: 'collapse',
    name: 'Lookup',
    key: 'lookup',
    auth: EDITOR,
    icon: <TableRowsIcon fontSize="small" />,
    route: ROUTE_LOOKUP,
    component: <Lookup />,
  },
  /*{
      type: 'hidden',
      name: 'Notifications',
      key: 'notifications',
      auth: VIEWER,
      icon: <Icon fontSize="small">notifications</Icon>,
      route: '/notifications',
      component: <Notifications />,
    },*/
  {
    type: 'hidden',
    name: 'Clienti Lookup',
    key: 'lkp_clienti',
    route: ROUTE_CLIENTI,
    component: <Clienti />,
  },
  {
    type: 'hidden',
    name: 'Apparecchiature Lookup',
    key: 'lkp_apparecchiature',
    route: ROUTE_APPARECCHIATURE,
    component: <Apparecchiature />,
  },
  {
    type: 'hidden',
    name: 'Certificazioni Lookup',
    key: 'lkp_certificazioni',
    route: ROUTE_CERTIFICAZIONI,
    component: <Certificazioni />,
  },
  {
    type: 'hidden',
    name: 'Richiedenti Lookup',
    key: 'lkp_richiedenti',
    route: ROUTE_RICHIEDENTI,
    component: <Richiedenti />,
  },
  {
    type: 'hidden',
    name: 'Tipi di lavoro Lookup',
    key: 'lkp_work',
    route: ROUTE_TIPILAVORO,
    component: <TipiLavori />,
  },
  {
    type: 'hidden',
    name: 'Materiali Lookup',
    key: 'lkp_materiali',
    route: ROUTE_MATERIALI,
    component: <Materiali />,
  },
  {
    type: 'hidden',
    name: 'Modelli Lookup',
    key: 'lkp_modelli',
    route: ROUTE_MODELLI,
    component: <Modelli />,
  },
  {
    type: 'hidden',
    name: 'Costruttori Lookup',
    key: 'lkp_costruttori',
    route: ROUTE_COSTRUTTORI,
    component: <Costruttori />,
  },
  {
    type: 'hidden',
    name: 'Attivita Lookup',
    key: 'lkp_attivita',
    route: ROUTE_ATTIVITA,
    component: <Attivita />,
  },
  {
    type: 'hidden',
    name: 'Parti Lookup',
    key: 'lkp_parti',
    route: ROUTE_PARTI,
    component: <Parti />,
  },
  /****** OTHER PAGE ***/
  {
    type: 'hidden',
    name: 'Credits',
    key: 'credits',
    route: ROUTE_CREDITS,
    component: <Credits />,
  },
  {
    type: 'hidden',
    name: 'Logs',
    key: 'logs',
    route: ROUTE_LOG,
    component: <Log />,
  },
];

export default routes;
