import PropTypes from 'prop-types';
import users from 'assets/images/users.png';

import MDBox from '../MDBox';
import MDTypography from '../MDTypography';
import MDAvatar from '../MDAvatar';

export const UserIdentified = ({ id, name, surname }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar src={users} name={name} size="sm" />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name} {surname}
      </MDTypography>
      <MDTypography variant="caption">{id}</MDTypography>
    </MDBox>
  </MDBox>
);

UserIdentified.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
};
