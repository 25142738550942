/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Skeleton by: www.creative-tim.com

Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Backdrop, CircularProgress } from '@mui/material';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import MDSnackbar from 'components/MDSnackbar';
import LookupDataTable from 'reducer/LookupDataTable';
import { SnackbarEdit } from 'components/SnackbarEdit';
import { AGGIORNA, INSERISCI, RIMUOVI, ROUTE_DASHBOARD, ROUTE_LOGIN } from 'context/constants';
import {
  deleteApparecchiatura,
  getApparecchiature,
  insertApparecchiatura,
  updateApparecchiatura,
} from 'api/lookup';
import { findElement } from 'lib/utils';
import { checkAuth, EDITOR } from 'context/auth';
import { useNavigate } from 'react-router-dom';
import { getLocalStorage, LS_AUTH } from 'lib/storage';

import { LookupForm } from '../form';

function Apparecchiature() {
  const managedAction = [RIMUOVI, AGGIORNA];
  const __SECTION_TITLE = 'Apparecchiature';
  const __AUTH = EDITOR;
  const navigate = useNavigate();
  const token = getLocalStorage(LS_AUTH);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!token) {
      navigate(ROUTE_LOGIN);
      return false;
    }
    // NOTE: tutte le rotte sotto autenticazione, se di grado troppo alto devono RIDIRIGERE a Dashboard
    if (!!__AUTH && !checkAuth(__AUTH)) {
      navigate(ROUTE_DASHBOARD);
      return false;
    }
    return true;
  }, []);

  // MANAGE UI
  const [idElement, setIdElement] = useState(null);
  const [action, setAction] = useState(INSERISCI);

  // STATE FOR FORM
  const [name, setName] = useState('');
  const [active, setActive] = useState(false);
  const [formError, setFormError] = useState({ name: false, active: false });
  // reload table
  const [updateTable, setUpdateTable] = useState('initial');
  const [elements, setElements] = useState([]); //elementi totali provenienti dalla api in DataTable
  // STATE FOR LOADING
  const [openBackdrop, setOpenBackdrop] = useState(false);
  // OPEN SNACKBAR
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const resetFormElement = () => {
    setName('');
    setActive(false);
  };
  const resetFormValue = () => {
    resetFormElement();
    setAction(INSERISCI);
    setIdElement(null);
  };
  //const [crudAction, setCrudAction] = useState('insert');
  const openSnackbarAction = (id, toAction) => {
    !!id && setIdElement(id);
    !!toAction && setAction(toAction);
    resetFormElement();

    return setOpenSnackbar(true);
  };
  const closeSnackbarAction = () => {
    setIdElement(null);
    //setAction('');
    return setOpenSnackbar(false);
  };

  /******* CRUD *****/
  const updateAction = (id) => {
    setAction(AGGIORNA);
    // loading false
    //setOpenBackdrop(false);
    const elToEdit = findElement(elements, 'id', id);
    setName(elToEdit.nome);
    setActive(!!elToEdit.attivo);
  };
  const deleteAction = (id) => {
    setOpenBackdrop(true);
    deleteApparecchiatura(id).then((data) => {
      if (!!data.result) {
        // loading false
        setOpenBackdrop(false);
        // refresh table
        setUpdateTable(`delete-${id}-${+new Date()}`);
        resetFormValue();

        enqueueSnackbar(`Elemento [${id}] cancellato!`, {
          variant: 'success',
        });
      } else {
        console.warn(`Errore in deleteApparecchiatura: ${data.message}`);
        enqueueSnackbar(`Problemi in cancellazione! Check console.`, { variant: 'error' });
      }
    });
  };
  const performAction = (toAction) => {
    if (!managedAction.includes(toAction)) {
      return false;
    }
    //console.log('------ > sto performando azione %s su elemento %s', toAction, idElement);
    if (toAction === RIMUOVI) {
      deleteAction(idElement);
    }
    if (toAction === AGGIORNA) {
      updateAction(idElement);
    }
    return setOpenSnackbar(false);
  };

  /***** FORM ******/
  const handleSetNome = (e) => {
    return setName(e.target.value);
  };

  const handleSetActive = (e) => {
    //console.log('inserisci ----->', e.target.checked);
    return setActive(e.target.checked);
  };

  const actionToLookup = () => {
    if (!name) {
      setFormError({ ...formError, name: true });
      return;
    }
    setOpenBackdrop(true);
    if (action === AGGIORNA) {
      //console.log('UPDATE ----->', idElement);
      updateApparecchiatura(idElement, {
        nome: name,
        attivo: active,
      }).then((data) => {
        setOpenBackdrop(false);
        if (!!data.id) {
          // update table
          setUpdateTable(`upd-${data.id}-${+new Date()}`);
          resetFormValue();

          enqueueSnackbar(`Elemento ${action === AGGIORNA ? 'aggiornato' : 'inserito'}!`, {
            variant: 'success',
          });
        } else {
          console.warn(`Errore in updateApparecchiatura: ${data.message}`);
          enqueueSnackbar(
            `Problemi in ${action === AGGIORNA ? 'update' : 'inserimento'}! Check console.`,
            { variant: 'error' }
          );
        }
      });
    } else {
      insertApparecchiatura({
        nome: name,
        attivo: active,
      }).then((data) => {
        setOpenBackdrop(false);
        if (!!data.id) {
          // update table
          setUpdateTable(`new-${data.id}-${+new Date()}`);
          resetFormValue();

          enqueueSnackbar(`Elemento ${action === AGGIORNA ? 'aggiornato' : 'inserito'}!`, {
            variant: 'success',
          });
        } else {
          console.warn(`Errore in insertApparecchiatura: ${data.message}`);
          enqueueSnackbar(
            `Problemi in ${action === AGGIORNA ? 'update' : 'inserimento'}! Check console.`,
            { variant: 'error' }
          );
        }
      });
    }
  };

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Attenzione!"
      content={
        <SnackbarEdit
          action={action}
          callback={performAction}
          callbackClose={closeSnackbarAction}
        />
      }
      open={openSnackbar}
      onClose={closeSnackbarAction}
      close={closeSnackbarAction}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openBackdrop}
            >
              <CircularProgress color="success" />
            </Backdrop>
            <Card>
              <MDBox p={2} lineHeight={0}>
                <MDTypography variant="h3">{__SECTION_TITLE}</MDTypography>
              </MDBox>
              <MDBox p={2}>
                <Card variant="outlined">
                  <MDBox
                    mx={2}
                    mt={-2}
                    py={1}
                    px={1}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="md"
                    coloredShadow="warning"
                  >
                    <MDTypography variant="h6" color="white">
                      Attualmente presenti...
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <LookupDataTable
                      onDeleteClb={openSnackbarAction}
                      onEditClb={openSnackbarAction}
                      reupdate={updateTable}
                      onupdate={setElements}
                      getData={getApparecchiature}
                    />
                  </MDBox>
                </Card>
                <LookupForm
                  action={action}
                  formError={formError}
                  value={{
                    name,
                    active,
                  }}
                  handle={{
                    handleSetActive,
                    handleSetNome,
                    actionToLookup,
                  }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          {renderWarningSB}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Apparecchiature;
