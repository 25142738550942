import React from 'react';
import PropTypes from 'prop-types';

import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import { INSERISCI, AGGIORNA } from 'context/constants';
import MDButton from 'components/MDButton';
import {
  getApparecchiatureSelect,
  getCertificazioniSelect,
  getClientsSelect,
  getCostruttoriSelect,
  getMaterialiSelect,
  getModelliSelect,
  getAppaltatoriSelect,
  getTipiDiLavoriSelect,
  insertApparecchiatura,
  insertCertificazione,
  insertClient,
  insertCostruttore,
  insertMateriale,
  insertModello,
  insertAppaltatore,
  insertTipoDiLavoro,
} from 'api/lookup';

import MDAlert from 'components/MDAlert';
import RHDAutocomplete from 'components/RHDynamicAutocomplete';

export const CorrelatiForm = ({ handle, action, displayAction }) => {
  return (
    <Card variant="outlined" style={{ width: '100%' }}>
      <MDBox pt={4} pb={3} px={3}>
        {action !== AGGIORNA && (
          <MDAlert color="info" severity="info" dismissible>
            Attenzione! Devi prima inserire i dati del primo step (e salvare)!
          </MDAlert>
        )}
        <MDBox component="form" role="form">
          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
            <Grid item xs={12} md={6}>
              <RHDAutocomplete
                callingApi={getClientsSelect}
                insertApi={insertClient}
                control={handle.control}
                watch={handle.watch}
                formValue={handle.setValue}
                defaultDialog={{
                  ragione_sociale: { type: 'string', maxLength: 255, label: 'Ragione Sociale' },
                }}
                prepopulateKey={'ragione_sociale'}
                formName={'fk_id_cliente'}
                label={'Indica il cliente'}
                errors={handle.errors}
                disabled={displayAction === 'show'}
                /*rules={{ required: 'Scegli il cliente!' }}*/
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHDAutocomplete
                callingApi={getAppaltatoriSelect}
                insertApi={insertAppaltatore}
                control={handle.control}
                watch={handle.watch}
                formValue={handle.setValue}
                defaultDialog={{
                  nome: { type: 'string', maxLength: 255, label: 'Nome appaltatore' },
                }}
                prepopulateKey={'nome'}
                formName={'fk_id_richiedente'}
                label={"Indica l'appaltatore"}
                errors={handle.errors}
                disabled={displayAction === 'show'}
                /*rules={{ required: "Scegli l'appaltatore!" }}*/
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHDAutocomplete
                callingApi={getApparecchiatureSelect}
                insertApi={insertApparecchiatura}
                control={handle.control}
                watch={handle.watch}
                formValue={handle.setValue}
                defaultDialog={{
                  nome: { type: 'string', maxLength: 255, label: 'Nome apparecchiatura' },
                }}
                prepopulateKey={'nome'}
                formName={'fk_id_apparecchiatura'}
                label={"Indica l'apparecchiatura"}
                errors={handle.errors}
                disabled={displayAction === 'show'}
                /*rules={{ required: "Scegli l'apparecchiatura!" }}*/
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHDAutocomplete
                callingApi={getCertificazioniSelect}
                insertApi={insertCertificazione}
                control={handle.control}
                watch={handle.watch}
                formValue={handle.setValue}
                defaultDialog={{
                  nome: { type: 'string', maxLength: 255, label: 'Nome certificazione' },
                }}
                prepopulateKey={'nome'}
                formName={'fk_id_tipoCertificazione'}
                label={'Indica la certificazione'}
                errors={handle.errors}
                disabled={displayAction === 'show'}
                /*rules={{ required: 'Scegli la certificazione!' }}*/
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHDAutocomplete
                callingApi={getCostruttoriSelect}
                insertApi={insertCostruttore}
                control={handle.control}
                watch={handle.watch}
                formValue={handle.setValue}
                defaultDialog={{
                  nome: { type: 'string', maxLength: 255, label: 'Nome costruttore' },
                }}
                prepopulateKey={'nome'}
                formName={'fk_id_costruttore'}
                label={'Indica il costruttore'}
                errors={handle.errors}
                disabled={displayAction === 'show'}
                /*rules={{ required: 'Scegli il costruttore!' }}*/
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHDAutocomplete
                callingApi={getMaterialiSelect}
                insertApi={insertMateriale}
                control={handle.control}
                watch={handle.watch}
                formValue={handle.setValue}
                defaultDialog={{
                  nome: { type: 'string', maxLength: 255, label: 'Nome materiale' },
                }}
                prepopulateKey={'nome'}
                formName={'fk_id_materiale'}
                label={'Indica il materiale'}
                errors={handle.errors}
                disabled={displayAction === 'show'}
                /*rules={{ required: 'Scegli il materiale!' }}*/
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHDAutocomplete
                callingApi={getModelliSelect}
                insertApi={insertModello}
                control={handle.control}
                watch={handle.watch}
                formValue={handle.setValue}
                defaultDialog={{
                  nome: { type: 'string', maxLength: 255, label: 'Nome modello' },
                }}
                prepopulateKey={'nome'}
                formName={'fk_id_modello'}
                label={'Indica il modello'}
                errors={handle.errors}
                disabled={displayAction === 'show'}
                /*rules={{ required: 'Scegli il modello!' }}*/
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHDAutocomplete
                callingApi={getTipiDiLavoriSelect}
                insertApi={insertTipoDiLavoro}
                control={handle.control}
                watch={handle.watch}
                formValue={handle.setValue}
                defaultDialog={{
                  nome: { type: 'string', maxLength: 255, label: 'Nome tipo di lavoro' },
                }}
                prepopulateKey={'nome'}
                formName={'fk_id_tipoLavoro'}
                label={'Indica il il tipo di lavoro'}
                errors={handle.errors}
                disabled={displayAction === 'show'}
                /*rules={{ required: 'Scegli il tipo di lavoro!' }}*/
              />
            </Grid>
            <Grid item xs={12} md={6}>
              &nbsp;
            </Grid>
            <Grid item xs={12} md={6}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handle.handleSubmit(handle.onSubmit)}
                disabled={action !== AGGIORNA || displayAction === 'show'}
              >
                {action === INSERISCI ? 'Inserisci' : 'Aggiorna'}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
};

CorrelatiForm.defaultProps = {
  handle: {
    actionToLookup: () => {},
  },
  displayAction: 'edit',
};

CorrelatiForm.propTypes = {
  handle: PropTypes.any,
  action: PropTypes.string.isRequired,
  displayAction: PropTypes.string,
};
