/* eslint-disable no-param-reassign */
import {
  APPARECCHIATURA,
  APPARECCHIATURE,
  CERTIFICAZIONE,
  CERTIFICAZIONI,
  CLIENTE,
  CLIENTI,
  RICHIEDENTE,
  RICHIEDENTI,
  USER,
  USERS,
  TIPILAVORI,
  TIPILAVORO,
  MATERIALI,
  MATERIALE,
  MODELLI,
  MODELLO,
  COSTRUTTORI,
  COSTRUTTORE,
  ATTIVITA,
  PARTI,
  INTERVENTO,
  INTERVENTO_CORRELATI,
  INTERVENTO_DATI,
  DASHBOARD,
  ATTIVITA_IT,
  PARTI_IT,
  SEARCH,
  LASTLOG,
  deleteData,
  getBaseApi,
  getData,
  postData,
  putData,
  postDataFile,
} from './index';

// SEARCH
export const search = (payload) => postData(`${getBaseApi(SEARCH)}`, payload);

// DASHBOARD
export const getDashboardStatistics = () => getData(`${getBaseApi(DASHBOARD)}`);

// LOG
export const getLogs = () => getData(`${getBaseApi(LASTLOG)}`);

// TECH INTERVENTION + DATI + CORRELATI
export const getIntervento = (id) => getData(`${getBaseApi(INTERVENTO)}/${id}`);
export const getInterventiPerAnno = () => getData(`${getBaseApi(INTERVENTO)}/year`);
export const insertIntervento = (payload) => postDataFile(`${getBaseApi(INTERVENTO)}`, payload);
export const insertDati = (id, data) => postDataFile(`${getBaseApi(INTERVENTO_DATI)}`, data);

export const updateIntervento = (id, data) => postDataFile(`${getBaseApi(INTERVENTO)}/${id}`, data);
export const updateCorrelati = (id, data) =>
  putData(`${getBaseApi(INTERVENTO_CORRELATI)}/${id}`, data);
export const updateDati = (id, data) => postDataFile(`${getBaseApi(INTERVENTO_DATI)}/${id}`, data);

export const deleteIntervento = (id) => deleteData(`${getBaseApi(INTERVENTO)}/${id}`);

// TECH INTERVENTION + ATTIVITA + PARTI

/** TUTTE LE ATTIVITA RELATIVE AD UN INTERVENTO TECNICO ***/
export const getInterventoAttivita = (fkIdIt) => getData(`${getBaseApi(ATTIVITA_IT)}/${fkIdIt}`);
export const insertInterventoAttivita = (payload) =>
  postData(`${getBaseApi(ATTIVITA_IT)}/${payload.fk_id_it}`, payload);
/** RIMUOVE LA SPECIFICA ATTIVITA_IT **/
export const deleteInterventoAttivita = (id) => deleteData(`${getBaseApi(ATTIVITA_IT)}/${id}`);

/** TUTTE LE PARTI RELATIVE AD UN INTERVENTO TECNICO ***/
export const getInterventoParti = (fkIdIt) => getData(`${getBaseApi(PARTI_IT)}/${fkIdIt}`);
export const insertInterventoParti = (payload) =>
  postData(`${getBaseApi(PARTI_IT)}/${payload.fk_id_it}`, payload);
/** RIMUOVE LA SPECIFICA PARTE_IT **/
export const deleteInterventoParti = (id) => deleteData(`${getBaseApi(PARTI_IT)}/${id}`);

// USERS
export const getUsers = () => getData(`${getBaseApi(USERS)}`);
export const insertUser = (payload) => postData(`${getBaseApi(USER)}`, payload);
export const deleteUser = (id) => deleteData(`${getBaseApi(USER)}/${id}`);
export const updateUser = (id, data) => putData(`${getBaseApi(USER)}/${id}`, { ...data });

// clienti
export const getClients = () => getData(`${getBaseApi(CLIENTI)}`);
export const getClientsSelect = () => getData(`${getBaseApi(CLIENTI)}/select`);
export const getClientsSelectValue = (id) => getData(`${getBaseApi(CLIENTI)}/select/${id}`);
export const insertClient = (payload) => postData(`${getBaseApi(CLIENTE)}`, payload);
export const deleteClient = (id) => deleteData(`${getBaseApi(CLIENTE)}/${id}`);
export const updateClient = (id, data) => putData(`${getBaseApi(CLIENTE)}/${id}`, { ...data });

// apparecchiature
export const getApparecchiature = () => getData(`${getBaseApi(APPARECCHIATURE)}`);
export const getApparecchiatureSelect = () => getData(`${getBaseApi(APPARECCHIATURE)}/select`);
export const getApparecchiatureSelectValue = (id) =>
  getData(`${getBaseApi(APPARECCHIATURE)}/select/${id}`);
export const insertApparecchiatura = (payload) =>
  postData(`${getBaseApi(APPARECCHIATURA)}`, payload);
export const deleteApparecchiatura = (id) => deleteData(`${getBaseApi(APPARECCHIATURA)}/${id}`);
export const updateApparecchiatura = (id, data) =>
  putData(`${getBaseApi(APPARECCHIATURA)}/${id}`, { ...data });

// certificazioni
export const getCertificazioni = () => getData(`${getBaseApi(CERTIFICAZIONI)}`);
export const getCertificazioniSelect = () => getData(`${getBaseApi(CERTIFICAZIONI)}/select`);
export const getCertificazioniSelectValue = (id) =>
  getData(`${getBaseApi(CERTIFICAZIONI)}/select/${id}`);
export const insertCertificazione = (payload) => postData(`${getBaseApi(CERTIFICAZIONE)}`, payload);
export const deleteCertificazione = (id) => deleteData(`${getBaseApi(CERTIFICAZIONE)}/${id}`);
export const updateCertificazione = (id, data) =>
  putData(`${getBaseApi(CERTIFICAZIONE)}/${id}`, { ...data });

// appaltatori
export const getAppaltatori = () => getData(`${getBaseApi(RICHIEDENTI)}`);
export const getAppaltatoriSelect = () => getData(`${getBaseApi(RICHIEDENTI)}/select`);
export const getAppaltatoriSelectValue = (id) => getData(`${getBaseApi(RICHIEDENTI)}/select/${id}`);
export const insertAppaltatore = (payload) => postData(`${getBaseApi(RICHIEDENTE)}`, payload);
export const deleteAppaltatore = (id) => deleteData(`${getBaseApi(RICHIEDENTE)}/${id}`);
export const updateAppaltatore = (id, data) =>
  putData(`${getBaseApi(RICHIEDENTE)}/${id}`, { ...data });

// tipi di lavori
export const getTipiDiLavori = () => getData(`${getBaseApi(TIPILAVORI)}`);
export const getTipiDiLavoriSelect = () => getData(`${getBaseApi(TIPILAVORI)}/select`);
export const getTipiDiLavoriSelectValue = (id) => getData(`${getBaseApi(TIPILAVORI)}/select/${id}`);
export const insertTipoDiLavoro = (payload) => postData(`${getBaseApi(TIPILAVORO)}`, payload);
export const deleteTipoDiLavoro = (id) => deleteData(`${getBaseApi(TIPILAVORO)}/${id}`);
export const updateTipoDiLavoro = (id, data) =>
  putData(`${getBaseApi(TIPILAVORO)}/${id}`, { ...data });

// materiali
export const getMateriali = () => getData(`${getBaseApi(MATERIALI)}`);
export const getMaterialiSelect = () => getData(`${getBaseApi(MATERIALI)}/select`);
export const getMaterialiSelectValue = (id) => getData(`${getBaseApi(MATERIALI)}/select/${id}`);
export const insertMateriale = (payload) => postData(`${getBaseApi(MATERIALE)}`, payload);
export const deleteMateriale = (id) => deleteData(`${getBaseApi(MATERIALE)}/${id}`);
export const updateMateriale = (id, data) => putData(`${getBaseApi(MATERIALE)}/${id}`, { ...data });

// modelli
export const getModelli = () => getData(`${getBaseApi(MODELLI)}`);
export const getModelliSelect = () => getData(`${getBaseApi(MODELLI)}/select`);
export const getModelliSelectValue = (id) => getData(`${getBaseApi(MODELLI)}/select/${id}`);
export const insertModello = (payload) => postData(`${getBaseApi(MODELLO)}`, payload);
export const deleteModello = (id) => deleteData(`${getBaseApi(MODELLO)}/${id}`);
export const updateModello = (id, data) => putData(`${getBaseApi(MODELLO)}/${id}`, { ...data });

// costruttori
export const getCostruttori = () => getData(`${getBaseApi(COSTRUTTORI)}`);
export const getCostruttoriSelect = () => getData(`${getBaseApi(COSTRUTTORI)}/select`);
export const getCostruttoriSelectValue = (id) => getData(`${getBaseApi(COSTRUTTORI)}/select/${id}`);
export const insertCostruttore = (payload) => postData(`${getBaseApi(COSTRUTTORE)}`, payload);
export const deleteCostruttore = (id) => deleteData(`${getBaseApi(COSTRUTTORE)}/${id}`);
export const updateCostruttore = (id, data) =>
  putData(`${getBaseApi(COSTRUTTORE)}/${id}`, { ...data });

// attivita
export const getAttivita = () => getData(`${getBaseApi(ATTIVITA)}`);
export const getAttivitaSelect = async () => {
  const data = await getData(`${getBaseApi(ATTIVITA)}/select`);

  return data.map((el) => {
    el.label = `${el.nome} [${el.codice}]`;
    return el;
  });
};
export const getAttivitaSelectValue = (id) => getData(`${getBaseApi(ATTIVITA)}/select/${id}`);
export const insertAttivita = (payload) => postData(`${getBaseApi(ATTIVITA)}`, payload);
export const deleteAttivita = (id) => deleteData(`${getBaseApi(ATTIVITA)}/${id}`);
export const updateAttivita = (id, data) => putData(`${getBaseApi(ATTIVITA)}/${id}`, { ...data });

// parti
export const getParti = () => getData(`${getBaseApi(PARTI)}`);
export const getPartiSelect = async () => {
  const data = await getData(`${getBaseApi(PARTI)}/select`);

  return data.map((el) => {
    el.label = `${el.nome} [${el.codice}]`;
    return el;
  });
};
export const getPartiSelectValue = (id) => getData(`${getBaseApi(PARTI)}/select/${id}`);
export const insertParti = (payload) => postData(`${getBaseApi(PARTI)}`, payload);
export const deleteParti = (id) => deleteData(`${getBaseApi(PARTI)}/${id}`);
export const updateParti = (id, data) => putData(`${getBaseApi(PARTI)}/${id}`, { ...data });
