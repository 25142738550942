import React from 'react';
import PropTypes from 'prop-types';

import MDBox from 'components/MDBox';
import { Alert, AlertTitle, InputAdornment } from '@mui/material';

import { INSERISCI } from 'context/constants';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

export const AttivitaForm = ({ handle, action }) => {
  return (
    <Card variant="outlined">
      <MDBox pt={4} pb={3} px={3}>
        {handle.submitForm && (
          <Alert severity="success" onClose={handle.handleCloseAlert}>
            <AlertTitle>Good Job!</AlertTitle>
            Contenuto {action === INSERISCI ? 'inserito' : 'aggiornato'}!
          </Alert>
        )}
        <MDBox component="form" role="form">
          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
            <Grid item xs={12} md={4}>
              <MDInput
                type="text"
                label="Nome"
                variant="standard"
                focused={!!handle.watch('nome')}
                fullWidth
                {...handle.register('nome', {
                  required: 'Obbligatorio!',
                  maxLength: {
                    value: 100,
                    message: 'Max. 100 caratteri', // JS only: <p>error message</p> TS only support string
                  },
                })}
                error={!!handle.errors?.nome}
                helperText={handle.errors?.nome?.message}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MDInput
                type="text"
                label="Codice"
                variant="standard"
                focused={!!handle.watch('codice')}
                fullWidth
                {...handle.register('codice', {
                  required: false,
                  pattern: {
                    value: 20,
                    message: 'Max. 20 caratteri', // JS only: <p>error message</p> TS only support string
                  },
                })}
                error={!!handle.errors?.codice}
                helperText={handle.errors?.codice?.message}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MDInput
                type="text"
                label="Costo Unitario"
                variant="standard"
                fullWidth
                {...handle.register('costo_unitario', {
                  required: false,
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <p>&euro;</p>
                    </InputAdornment>
                  ),
                  type: 'number',
                  min: 1,
                  step: 1,
                }}
                error={!!handle.errors?.costo_unitario}
                helperText={handle.errors?.costo_unitario?.message}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MDInput
                type="text"
                label="Descrizione"
                variant="standard"
                focused={!!handle.watch('descrizione')}
                fullWidth
                multiline
                rows={2}
                {...handle.register('descrizione', {
                  required: false,
                })}
                error={!!handle.errors?.descrizione}
                helperText={handle.errors?.descrizione?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handle.handleSubmit(handle.onSubmit)}
              >
                {action === INSERISCI ? 'Inserisci' : 'Aggiorna'}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
};

AttivitaForm.defaultProps = {
  handle: {
    actionToLookup: () => {},
  },
};

AttivitaForm.propTypes = {
  handle: PropTypes.any,
  action: PropTypes.string.isRequired,
  initState: PropTypes.object.isRequired,
};
