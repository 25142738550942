/**
=========================================================
* Soft UI Dashboard React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import Grid from '@mui/material/Grid';
import { Backdrop, CircularProgress } from '@mui/material';

// Soft UI Dashboard React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDSnackbar from 'components/MDSnackbar';
import { SnackbarEdit } from 'components/SnackbarEdit';

// Data
import TechInterventionTable from 'reducer/TechInterventionTable';
import { deleteIntervento, getInterventiPerAnno } from 'api/lookup';
import { navigateToRoute } from 'lib/utils';
import { AGGIORNA, RIMUOVI, ROUTE_TI_VIEW } from 'context/constants';

function TableLastTI({ tiMonth }) {
  const managedAction = [RIMUOVI, AGGIORNA];
  const navigate = useNavigate();
  // reload table
  const [updateTable, setUpdateTable] = useState('initial'); //setUpdateTable
  const [, setElements] = useState([]); //elementi totali provenienti dalla api in DataTable  //elements

  const [idElement, setIdElement] = useState(null);
  const [action, setAction] = useState(RIMUOVI);
  const { enqueueSnackbar } = useSnackbar();

  // STATE FOR LOADING
  const [openBackdrop, setOpenBackdrop] = useState(false);
  // OPEN SNACKBAR
  const [openSnackbar, setOpenSnackbar] = useState(false);

  /***** CRUD ****/
  const deleteAction = (id) => {
    setOpenBackdrop(true);
    deleteIntervento(id).then((data) => {
      if (!!data.result) {
        // loading false
        setOpenBackdrop(false);
        // refresh table
        setUpdateTable(`delete-${id}-${+new Date()}`);

        enqueueSnackbar(`Intervento [${id}] cancellato!`, {
          variant: 'success',
        });
      } else {
        console.warn(`Errore in deleteIntervento: ${data.message}`);
        enqueueSnackbar(`Problemi in cancellazione! Check console.`, { variant: 'error' });
      }
    });
  };

  /**** SNACKBAR UI ****/
  const openSnackbarAction = (id, toAction) => {
    !!id && setIdElement(id);
    !!toAction && setAction(toAction);

    return setOpenSnackbar(true);
  };
  const closeSnackbarAction = () => {
    setIdElement(null);
    return setOpenSnackbar(false);
  };
  const performAction = (toAction) => {
    if (!managedAction.includes(toAction)) {
      return false;
    }
    //console.log('------ > sto performando azione %s su elemento %s', toAction, idElement);
    if (toAction === RIMUOVI) {
      deleteAction(idElement);
    }
    /*if (toAction === AGGIORNA) {
      updateAction(idElement);
    }*/
    return setOpenSnackbar(false);
  };

  const gotoTechIntervention = (id, toAction) => {
    const basePath = `${ROUTE_TI_VIEW}/${id}`;
    toAction === AGGIORNA
      ? navigateToRoute(navigate, `${basePath}/edit`)
      : navigateToRoute(navigate, `${basePath}/show`);
  };

  const renderSnackBar = (
    <MDSnackbar
      color="error"
      icon="delete"
      title="Attenzione!"
      content={
        <SnackbarEdit
          action={action}
          overrideText={`Stai per eliminare l'intervento tecnico [${idElement}].\n\rQuesto azione IRREVERSIBILE comporta anche\n\rla cancellazione di TUTTI i dati associati, compresi gli allegati.\n\rVuoi continuare?`}
          callback={performAction}
          callbackClose={closeSnackbarAction}
        />
      }
      open={openSnackbar}
      onClose={closeSnackbarAction}
      close={closeSnackbarAction}
      bgWhite
    />
  );

  return (
    <Card>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="success" />
      </Backdrop>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Interventi dell&#39;anno
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              sx={{
                fontWeight: 'bold',
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              done
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>{tiMonth} aggiunti</strong> questo mese
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox>
        <TechInterventionTable
          onEditClb={gotoTechIntervention}
          onShowClb={gotoTechIntervention}
          onDeleteClb={openSnackbarAction}
          reupdate={updateTable}
          onupdate={setElements}
          getData={getInterventiPerAnno}
        />
      </MDBox>
      <Grid item xs={12} sm={6} lg={3}>
        {renderSnackBar}
      </Grid>
    </Card>
  );
}

TableLastTI.defaultProps = {
  tiMonth: 0,
};

TableLastTI.propTypes = {
  tiMonth: PropTypes.number,
};

export default TableLastTI;
