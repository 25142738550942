/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Skeleton by: www.creative-tim.com

Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

// @mui material components
import Grid from '@mui/material/Grid';

import { Backdrop, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

// Overview page components
import Header from 'layouts/techintervention/components/Header';
import MDSnackbar from 'components/MDSnackbar';
import { SnackbarEdit } from 'components/SnackbarEdit';
import { checkAuth, VIEWER } from 'context/auth';
import { getLocalStorage, LS_AUTH, LS_AUTHID } from 'lib/storage';
import { getIntervento, insertIntervento } from 'api/lookup';
import { dateToYYYYMMDD, isArray, isEmpty, isObject } from 'lib/utils';
import { getUpdateTechInterventionByTab } from 'reducer/api/mappingApi';

import { AGGIORNA, INSERISCI, RIMUOVI, ROUTE_DASHBOARD, ROUTE_LOGIN } from 'context/constants'; //AGGIORNA
import { InfoForm } from './form/info';
import { DatiForm } from './form/dati';
import { CorrelatiForm } from './form/correlati';
import AttivitaPartiPage from './components/AttivitaPartiPage';

/**
 * Mapping numero tab (idx array) con tipologia tab mappingState
 * @type {{correlati: number}}
 */
const mappingStep = ['intervento', 'correlati', 'dati', 'attpar'];

const keyForFile = [
  'allegato',
  'manutenzioni',
  'chiamata_file',
  'preventivo_file',
  'ordinecliente_file',
  'contratto_file',
];

const mappingState = {
  intervento: [
    'matricola',
    'inventario',
    'descrizione',
    'oreLavorate',
    'dirittoFissoDiChiamata',
    'totale',
    'tecnicoEsecutore',
    'allegato',
    'manutenzioni',
    'note',
    'anno',
    'location',
    'dataIntervento',
  ],
  correlati: [
    'id',
    'fk_id_utente',
    'fk_id_cliente',
    'fk_id_richiedente',
    'fk_id_apparecchiatura',
    'fk_id_tipoCertificazione',
    'fk_id_costruttore',
    'fk_id_materiale',
    'fk_id_modello',
    'fk_id_tipoLavoro',
  ],
  dati: [
    'id',
    'chiamata_n',
    'chiamata_del',
    'chiamata_file',
    'preventivo_n',
    'preventivo_del',
    'preventivo_file',
    'ordinecliente_n',
    'ordinecliente_del',
    'ordinecliente_file',
    'contratto_n',
    'contratto_del',
    'contratto_file',
  ],
};

function TechIntervention() {
  const __SECTION_TITLE = 'Tech Intervention';
  const __AUTH = VIEWER; //TODO: occhio... le singole azioni da modificare
  const navigate = useNavigate();
  const token = getLocalStorage(LS_AUTH);
  const { idParams, paramsAction } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (!token) {
      navigate(ROUTE_LOGIN);
      return false;
    }
    // NOTE: tutte le rotte sotto autenticazione, se di grado troppo alto devono RIDIRIGERE a Dashboard
    if (!!__AUTH && !checkAuth(__AUTH)) {
      navigate(ROUTE_DASHBOARD);
      return false;
    }
    return true;
  }, []);

  const [tab, setTab] = useState(0);
  // STATE FOR LOADING
  const [openBackdrop, setOpenBackdrop] = useState(false);
  // OPEN SNACKBAR
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // MANAGE UI
  const [idElement, setIdElement] = useState(idParams); //setIdElement
  const [action, setAction] = useState(INSERISCI);

  /*********************  GESTIONE STATE FORM   ******************/
  //flatstate
  const initState = {
    id: null,
    matricola: '',
    inventario: '',
    descrizione: '',
    oreLavorate: '',
    dirittoFissoDiChiamata: '',
    totale: null,
    tecnicoEsecutore: null,
    allegato: {},
    manutenzioni: {},
    note: null,
    anno: null,
    location: '',
    dataIntervento: null,
    //dati
    fk_id_dati: null,
    chiamata_n: null,
    chiamata_del: null,
    chiamata_file: {},
    preventivo_n: null,
    preventivo_del: null,
    preventivo_file: {},
    ordinecliente_n: null,
    ordinecliente_del: null,
    ordinecliente_file: {},
    contratto_n: null,
    contratto_del: null,
    contratto_file: {},
    //correlati
    fk_id_utente: getLocalStorage(LS_AUTHID),
    fk_id_cliente: null,
    fk_id_richiedente: null,
    fk_id_apparecchiatura: null,
    fk_id_tipoCertificazione: null,
    fk_id_costruttore: null,
    fk_id_materiale: null,
    fk_id_modello: null,
    fk_id_tipoLavoro: null,
  };
  const [techIntervention, setTechIntervention] = useState(initState); //setTechIntervention

  const {
    reset,
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: techIntervention,
  });

  useEffect(() => {
    reset(techIntervention);
    setAction(techIntervention.id !== null ? AGGIORNA : INSERISCI);
    setIdElement(techIntervention.id);
  }, [techIntervention]);

  const mappingDataFromDB = (data) => {
    if (isEmpty(data)) {
      const keyclose = enqueueSnackbar(
        `L'intervento tecnico scelto non esiste... prova a sceglierne un altro!`,
        {
          variant: 'warning',
          persist: true,
        }
      );
      setTimeout(() => {
        closeSnackbar(keyclose);
      }, 3000);
      setTimeout(() => {
        navigate(ROUTE_DASHBOARD);
      }, 3500);

      return false;
    }

    const keyToRemove = [
      'cliente',
      'utente',
      'richiedente',
      'tipo_lavoro',
      'apparecchiatura',
      'costruttore',
      'modello',
      'certificazione',
      'materiale',
      'created_at',
      'updated_at',
      'fk_id_it',
    ];
    const mappedState = {};
    Object.keys(initState).forEach((key) => {
      if (!keyToRemove.includes(key)) {
        if ('undefined' !== typeof data[key]) {
          mappedState[key] = data[key];
        }
        if (!!data.datiit && 'undefined' !== typeof data.datiit[key] && key !== 'id') {
          mappedState[key] = data.datiit[key];
        }
      }
    });
    mappedState.fk_id_dati = data?.datiit?.id;

    //remapping datetime value for type=date
    mappedState.chiamata_del = !!mappedState?.chiamata_del
      ? dateToYYYYMMDD(mappedState.chiamata_del)
      : null;
    mappedState.preventivo_del = !!mappedState?.preventivo_del
      ? dateToYYYYMMDD(mappedState.preventivo_del)
      : null;
    mappedState.ordinecliente_del = !!mappedState.ordinecliente_del
      ? dateToYYYYMMDD(mappedState.ordinecliente_del)
      : null;
    mappedState.contratto_del = !!mappedState.contratto_del
      ? dateToYYYYMMDD(mappedState.contratto_del)
      : null;
    mappedState.dataIntervento = !!mappedState?.dataIntervento
      ? dateToYYYYMMDD(mappedState.dataIntervento)
      : null;

    /*** set intervention from DB *****/
    setTechIntervention(mappedState);

    return true;
  };

  useEffect(() => {
    if (idParams !== null && typeof idParams !== 'undefined') {
      getIntervento(idParams).then((data) => {
        mappingDataFromDB(data[0]);
      });
    } else {
      //resetState
      setTechIntervention(initState);
    }
  }, [idParams]);

  /*********************  GESTIONE FILE   ******************/

  const closeSnackbarAction = () => {
    return setOpenSnackbar(false);
  };

  /***** FORM ******/
  const getDataFormFromTab = (formData) => {
    //console.log('IN TAB: ', tab, formData);
    const payload = {};
    const step = mappingStep[tab];
    const mappingKeyState = mappingState[step];

    mappingKeyState.forEach((key) => {
      if (keyForFile.includes(key)) {
        //solo per file, allegati, and so on...
        // se array => binario, se stringa => url file (ex: in editing)
        payload[key] =
          isArray(formData[key]) && 'undefined' !== formData[key][0]
            ? formData[key][0]
            : !isEmpty(formData[key])
            ? formData[key]
            : '';
      } else {
        payload[key] = isObject(formData[key])
          ? formData[key].id
          : !isEmpty(formData[key])
          ? formData[key]
          : '';
      }
      if (key === 'id') {
        if ('dati' === step) {
          payload.fk_id_it = formData[key] === null ? techIntervention.id : formData[key];
        } else {
          payload[key] = formData[key] === null ? techIntervention.id : formData[key];
        }
      }
    });

    return payload;
  };

  const getActionForDatiTab = (formData) => {
    return techIntervention.fk_id_dati === null ? INSERISCI : AGGIORNA;
  };

  const onSubmit = (formData) => {
    const mappedPayload = getDataFormFromTab(formData);
    //console.log('AL SUBMIT', formData, '->', mappedPayload, action, idElement, mappingState);

    setOpenBackdrop(true);
    if (action === INSERISCI) {
      // QUI SOLO DATI DEL PRIMO TAB... gli altri sono tutti update
      insertIntervento(mappedPayload).then((data) => {
        setOpenBackdrop(false);
        if (!!data.id) {
          setTechIntervention({
            ...techIntervention,
            ...data,
          });
          enqueueSnackbar(`Intervento Tecnico inserito!`, { variant: 'success' });
        } else {
          console.warn(`Errore in insertIntervento: ${data.message}`);
          enqueueSnackbar(`Problemi in inserimento! Check console.`, { variant: 'error' });
        }
      });
    } else {
      const actionForDati = getActionForDatiTab(formData);
      const updateFn = getUpdateTechInterventionByTab(tab, actionForDati);

      updateFn(idElement, mappedPayload).then((data) => {
        setOpenBackdrop(false);
        if (!!data.id || !!data.fk_id_it) {
          if ('dati' === mappingStep[tab]) {
            //DATI STEP
            data.fk_id_dati = data.id;
            if (!!data?.fk_id_it) {
              data.fk_id_it = null;
              delete data.fk_id_it;
            }
            if (!!data.id) {
              data.id = null;
              delete data.id;
            }
          }
          setTechIntervention({
            ...techIntervention,
            ...data,
          });
          enqueueSnackbar(`Intervento Tecnico aggiornato!`, { variant: 'success' });
        } else {
          console.warn(`Errore in updateIntervento: ${data.message}`);
          enqueueSnackbar(`Problemi in update! Check console.`, { variant: 'error' });
        }
      });
    }
  };

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Attenzione!"
      content={
        <SnackbarEdit
          action={action}
          callback={() => {}} //performAction
          callbackClose={closeSnackbarAction}
        />
      }
      open={openSnackbar}
      onClose={closeSnackbarAction}
      close={closeSnackbarAction}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header
        setTab={setTab}
        tabValue={tab}
        title={__SECTION_TITLE}
        techIntervention={techIntervention}
        displayAction={paramsAction}
      >
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12} sx={{ display: 'flex' }}>
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
              >
                <CircularProgress color="success" />
              </Backdrop>
            </Grid>
            {tab === 0 && (
              <Grid item xs={12} md={12} xl={12} sx={{ display: 'flex' }}>
                <InfoForm
                  id="info-form"
                  action={action}
                  displayAction={paramsAction}
                  handle={{
                    handleSubmit,
                    register,
                    watch,
                    control,
                    onSubmit,
                    errors,
                    setValue,
                  }}
                />
              </Grid>
            )}
            {tab === 1 && (
              <Grid item xs={12} md={12} xl={12} sx={{ display: 'flex' }}>
                <CorrelatiForm
                  id="dati-form"
                  action={action}
                  displayAction={paramsAction}
                  handle={{
                    handleSubmit,
                    register,
                    watch,
                    control,
                    onSubmit,
                    errors,
                    setValue,
                  }}
                />
              </Grid>
            )}
            {tab === 2 && (
              <Grid item xs={12} md={12} xl={12} sx={{ display: 'flex' }}>
                <DatiForm
                  id="dati-form"
                  action={action}
                  displayAction={paramsAction}
                  handle={{
                    handleSubmit,
                    register,
                    watch,
                    control,
                    onSubmit,
                    errors,
                    setValue,
                  }}
                />
              </Grid>
            )}
            {tab === 3 && (
              <Grid item xs={12} md={12} xl={12} sx={{ display: 'flex' }}>
                <AttivitaPartiPage
                  id="attivitaparti-page"
                  action={action}
                  techIntervention={idElement}
                  displayAction={paramsAction}
                  handle={{
                    handleSubmit,
                    register,
                    watch,
                    control,
                    onSubmit,
                    errors,
                    setValue,
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {renderWarningSB}
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default TechIntervention;
