/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Skeleton by: www.creative-tim.com

Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
// import ReportsLineChart from 'examples/Charts/LineCharts/ReportsLineChart';
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard';

// Data
// import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';

// Dashboard components
import TableLastTI from 'layouts/dashboard/components/Interventi';
import { getLocalStorage, LS_AUTH } from '../../lib/storage';
import { ROUTE_DASHBOARD, ROUTE_LOGIN } from '../../context/constants';
import { checkAuth, VIEWER } from '../../context/auth';
import { getDashboardStatistics } from '../../api/lookup';

function Dashboard() {
  const __AUTH = VIEWER;
  const navigate = useNavigate();
  const token = getLocalStorage(LS_AUTH);

  const [statistics, setStatistics] = useState({});

  useEffect(() => {
    if (!token) {
      navigate(ROUTE_LOGIN);
      return false;
    }
    // NOTE: tutte le rotte sotto autenticazione, se di grado troppo alto devono RIDIRIGERE a Dashboard
    if (!!__AUTH && !checkAuth(__AUTH)) {
      navigate(ROUTE_DASHBOARD);
      return false;
    }
    return true;
  }, []);

  useEffect(() => {
    getDashboardStatistics().then((data) => {
      //TODO: magari spostare e centralizzare il controllo dell'Unauthorized
      if (!!data.status_code && 401 === +data.status_code) {
        return;
      }
      if (!!data.month) {
        setStatistics(data);
      }
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            {!!statistics.clients && (
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="group"
                  title="Clienti"
                  count={statistics.clients}
                  percentage={{
                    color: statistics.perc_clients.charAt(0) === '+' ? 'success' : 'warning',
                    amount: statistics.perc_clients,
                    label: 'nel mese',
                  }}
                />
              </MDBox>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {!!statistics.models && (
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="moretime"
                  title="Modelli"
                  count={statistics.models}
                  percentage={{
                    color: statistics.perc_models.charAt(0) === '+' ? 'success' : 'warning',
                    amount: statistics.perc_models,
                    label: 'nel mese',
                  }}
                />
              </MDBox>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            {!!statistics.techInterventions && (
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Schede intervento"
                  count={statistics.techInterventions}
                  percentage={{
                    color: statistics.perc_ti.charAt(0) === '+' ? 'success' : 'warning',
                    amount: statistics.perc_ti,
                    label: 'nel mese',
                  }}
                />
              </MDBox>
            )}
          </Grid>
        </Grid>
        <MDBox my={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <TableLastTI tiMonth={statistics.techInterventionThisMonth} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

/**
 <Grid item xs={12} md={6} lg={4}>
 <MDBox>
 <ReportsLineChart
 color="dark"
 title="Interventi"
 description="Interventi per mese"
 date="just updated"
 chart={intervention}
 />
 </MDBox>
 </Grid>
 */

export default Dashboard;
