import React from 'react';
import PropTypes from 'prop-types';

import MDBox from 'components/MDBox';
import { InputAdornment } from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import { AGGIORNA, INSERISCI, VEDI, SHOW_TOTAL_IN_INFO_PANEL } from 'context/constants';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

import { labelFormatDate, onClickDate } from '../../../lib/utils';
import RHFileUpload from '../../../components/RHFileUpload';

export const InfoForm = ({ handle, action, displayAction }) => {
  return (
    <Card variant="outlined" style={{ width: '100%' }}>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
            <Grid item xs={12} md={4}>
              <MDInput
                type="text"
                label="Matricola"
                variant="standard"
                focused={!!handle.watch('matricola')}
                fullWidth
                {...handle.register('matricola', {
                  required: 'Obbligatorio!',
                })}
                error={!!handle.errors?.matricola}
                helperText={handle.errors?.matricola?.message}
                disabled={displayAction === 'show'}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MDInput
                type="text"
                label="Inventario"
                variant="standard"
                focused={!!handle.watch('inventario')}
                fullWidth
                {...handle.register('inventario')}
                error={!!handle.errors?.inventario}
                helperText={handle.errors?.inventario?.message}
                disabled={displayAction === 'show'}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MDInput
                type="text"
                label="Tecnico Esecutore"
                variant="standard"
                focused={!!handle.watch('tecnicoEsecutore')}
                fullWidth
                {...handle.register('tecnicoEsecutore')}
                error={!!handle.errors?.tecnicoEsecutore}
                helperText={handle.errors?.tecnicoEsecutore?.message}
                disabled={displayAction === 'show'}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MDInput
                type="text"
                label="Descrizione"
                variant="standard"
                focused={!!handle.watch('descrizione')}
                fullWidth
                multiline
                rows={3}
                {...handle.register('descrizione')}
                error={!!handle.errors?.descrizione}
                helperText={handle.errors?.descrizione?.message}
                disabled={displayAction === 'show'}
              />
            </Grid>
            <Grid item xs={12} md={!!SHOW_TOTAL_IN_INFO_PANEL ? 4 : 6}>
              <MDInput
                type="number"
                label="Ore lavorate"
                variant="standard"
                focused={
                  typeof handle.watch('oreLavorate') !== 'undefined' &&
                  !!handle.watch('oreLavorate')
                }
                fullWidth
                {...handle.register('oreLavorate')}
                error={!!handle.errors?.oreLavorate}
                helperText={handle.errors?.oreLavorate?.message}
                disabled={displayAction === 'show'}
              />
            </Grid>
            <Grid item xs={12} md={!!SHOW_TOTAL_IN_INFO_PANEL ? 4 : 6}>
              <MDInput
                type="number"
                label="Diritto fisso di chiamata"
                variant="standard"
                focused={
                  typeof handle.watch('dirittoFissoDiChiamata') !== 'undefined' &&
                  !!handle.watch('dirittoFissoDiChiamata')
                }
                fullWidth
                {...handle.register('dirittoFissoDiChiamata')}
                error={!!handle.errors?.dirittoFissoDiChiamata}
                helperText={handle.errors?.dirittoFissoDiChiamata?.message}
                disabled={displayAction === 'show'}
              />
            </Grid>
            {SHOW_TOTAL_IN_INFO_PANEL && (
              <Grid item xs={12} md={4}>
                <MDInput
                  type="number"
                  label="Totale"
                  variant="standard"
                  focused={!!handle.watch('totale')}
                  value={handle.watch('oreLavorate') * handle.watch('dirittoFissoDiChiamata')}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <p>&euro;</p>
                      </InputAdornment>
                    ),
                    type: 'number',
                    min: 1,
                    step: 1,
                  }}
                  {...handle.register('totale')}
                  error={!!handle.errors?.totale}
                  helperText={handle.errors?.totale?.message}
                  disabled={displayAction === 'show'}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <MDInput
                type="date"
                label="Data Intervento"
                variant="standard"
                focused={!!handle.watch('dataIntervento')}
                fullWidth
                {...handle.register('dataIntervento')}
                error={!!handle.errors?.dataIntervento}
                disabled={displayAction === 'show'}
                onClick={onClickDate}
                helperText={
                  !!handle.errors?.dataIntervento
                    ? handle.errors?.dataIntervento?.message
                    : labelFormatDate
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MDInput
                type="text"
                label="Location"
                variant="standard"
                focused={!!handle.watch('location')}
                fullWidth
                {...handle.register('location')}
                error={!!handle.errors?.location}
                helperText={handle.errors?.location?.message}
                disabled={displayAction === 'show'}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MDInput
                type="text"
                label="Anno"
                variant="standard"
                fullWidth
                focused
                value={!!handle.watch('anno') ? handle.watch('anno') : new Date().getFullYear()}
                {...handle.register('anno')}
                error={!!handle.errors?.anno}
                helperText={handle.errors?.anno?.message}
                disabled={displayAction === 'show'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFileUpload
                accept=".pdf,.xls,.xlsx,.doc,.docx,.zip"
                label="Allegato"
                multiple={false}
                updateFilesCb={(files) => {
                  handle.setValue('allegato', files);
                }}
                buttonText={'Clicca per caricare'}
                dragText={'Trascina il file oppure'}
                listText={'File(s) caricati'}
                register={handle.register}
                useName={'allegato'}
                disabled={displayAction === 'show'}
                urlFile={handle.watch('allegato')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFileUpload
                accept=".pdf,.xls,.xlsx,.doc,.docx,.zip"
                label="Manutenzioni"
                multiple={false}
                updateFilesCb={(files) => {
                  handle.setValue('manutenzioni', files);
                }}
                buttonText={'Clicca per caricare'}
                dragText={'Trascina il file oppure'}
                listText={'File(s) caricati'}
                register={handle.register}
                useName={'manutenzioni'}
                disabled={displayAction === 'show'}
                urlFile={handle.watch('manutenzioni')}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MDInput
                type="text"
                label="Note"
                variant="standard"
                focused={!!handle.watch('note')}
                fullWidth
                multiline
                rows={3}
                {...handle.register('note')}
                error={!!handle.errors?.note}
                helperText={handle.errors?.note?.message}
                disabled={displayAction === 'show'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              &nbsp;
            </Grid>
            <Grid item xs={12} md={6}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handle.handleSubmit(handle.onSubmit)}
                disabled={displayAction === VEDI}
              >
                {action === INSERISCI ? 'Inserisci' : 'Aggiorna'}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
};

InfoForm.defaultProps = {
  handle: {
    actionToLookup: () => {},
  },
  displayAction: AGGIORNA,
};

InfoForm.propTypes = {
  handle: PropTypes.any,
  action: PropTypes.string.isRequired,
  displayAction: PropTypes.string,
};
