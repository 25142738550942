/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Skeleton by: www.creative-tim.com

Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

import { ROUTE_DASHBOARD, ROUTE_LOGIN } from 'context/constants';
import { getLogs } from 'api/lookup';
import { checkAuth, ADMIN } from 'context/auth';
import { useNavigate } from 'react-router-dom';
import { getLocalStorage, LS_AUTH } from 'lib/storage';

import LogDataTable from 'reducer/LogDataTable';

function Log() {
  const __SECTION_TITLE = 'Logs Applicativi';
  const __AUTH = ADMIN;
  const navigate = useNavigate();
  const token = getLocalStorage(LS_AUTH);

  useEffect(() => {
    if (!token) {
      navigate(ROUTE_LOGIN);
      return false;
    }
    // NOTE: tutte le rotte sotto autenticazione, se di grado troppo alto devono RIDIRIGERE a Dashboard
    if (!!__AUTH && !checkAuth(__AUTH)) {
      navigate(ROUTE_DASHBOARD);
      return false;
    }
    return true;
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox p={2} lineHeight={0}>
                <MDTypography variant="h3">{__SECTION_TITLE}</MDTypography>
              </MDBox>
              <MDBox p={2}>
                <Card variant="outlined">
                  <MDBox
                    mx={2}
                    mt={-2}
                    py={1}
                    px={1}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="md"
                    coloredShadow="warning"
                  >
                    <MDTypography variant="h6" color="white">
                      Log degli ultimi 3 mesi...
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <LogDataTable getData={getLogs} />
                  </MDBox>
                </Card>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Log;
