import React from 'react';
import PropTypes from 'prop-types';

import MDBox from 'components/MDBox';
import { Alert, AlertTitle } from '@mui/material';

import { INSERISCI } from 'context/constants';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import MDTypography from '../../../components/MDTypography';

export const ClienteForm = ({ handle, action }) => {
  return (
    <Card variant="outlined">
      <MDBox pt={4} pb={3} px={3}>
        {handle.submitForm && (
          <Alert severity="success" onClose={handle.handleCloseAlert}>
            <AlertTitle>Good Job!</AlertTitle>
            Contenuto {action === INSERISCI ? 'inserito' : 'aggiornato'}!
          </Alert>
        )}
        <MDBox component="form" role="form">
          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
            <Grid item xs={12} md={12}>
              <MDInput
                type="text"
                label="Ragione Sociale"
                variant="standard"
                focused={!!handle.watch('ragione_sociale')}
                fullWidth
                {...handle.register('ragione_sociale', {
                  required: 'Obbligatorio!',
                  maxLength: {
                    value: 255,
                    message: 'Max. 255 caratteri', // JS only: <p>error message</p> TS only support string
                  },
                })}
                error={!!handle.errors?.ragione_sociale}
                helperText={handle.errors?.ragione_sociale?.message}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MDInput
                type="text"
                label="Email"
                variant="standard"
                focused={!!handle.watch('email')}
                fullWidth
                {...handle.register('email', {
                  required: false,
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: 'Inserire una email valida!', // JS only: <p>error message</p> TS only support string
                  },
                })}
                error={!!handle.errors?.email}
                helperText={handle.errors?.email?.message}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MDInput
                type="text"
                label="Telefono"
                variant="standard"
                fullWidth
                focused={!!handle.watch('telefono')}
                {...handle.register('telefono', {
                  required: false,
                })}
                error={!!handle.errors?.telefono}
                helperText={handle.errors?.telefono?.message}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MDInput
                type="text"
                label="Luogo"
                variant="standard"
                fullWidth
                focused={!!handle.watch('luogo')}
                {...handle.register('luogo', {
                  required: false,
                  maxLength: {
                    value: 200,
                    message: 'Max. 200 caratteri', // JS only: <p>error message</p> TS only support string
                  },
                })}
                error={!!handle.errors?.luogo}
                helperText={handle.errors?.luogo?.message}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MDInput
                type="text"
                label="P.IVA"
                variant="standard"
                fullWidth
                focused={!!handle.watch('piva')}
                {...handle.register('piva', {
                  required: false,
                  maxLength: {
                    value: 11,
                    message: 'Max. 11 caratteri', // JS only: <p>error message</p> TS only support string
                  },
                })}
                error={!!handle.errors?.piva}
                helperText={handle.errors?.piva?.message}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MDInput
                type="text"
                label="Codice Fiscale"
                variant="standard"
                fullWidth
                focused={!!handle.watch('cf')}
                {...handle.register('cf', {
                  required: false,
                  maxLength: {
                    value: 16,
                    message: 'Max. 16 caratteri', // JS only: <p>error message</p> TS only support string
                  },
                })}
                error={!!handle.errors?.cf}
                helperText={handle.errors?.cf?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Checkbox
                name="attivo"
                {...handle.register('attivo')}
                error={!!handle.errors?.attivo}
              />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
              >
                &nbsp;Attiva&nbsp;
              </MDTypography>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handle.handleSubmit(handle.onSubmit)}
              >
                {action === INSERISCI ? 'Inserisci' : 'Aggiorna'}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
};

ClienteForm.defaultProps = {
  handle: {
    actionToLookup: () => {},
  },
};

ClienteForm.propTypes = {
  handle: PropTypes.any,
  action: PropTypes.string.isRequired,
  initState: PropTypes.object.isRequired,
};
