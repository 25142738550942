/**
 =========================================================
 * Material Dashboard 2 React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components

// @mui material components
import { Card, Link } from '@mui/material';

// Authentication layout components
import CoverLayout from 'layouts/authentication/components/CoverLayout';

// Images
import bgImage from 'assets/images/bg-hi-tech2.webp';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import CreditProgress from 'components/RHCreditProgress';

function Credits() {
  return (
    <CoverLayout image={bgImage}>
      <MDTypography
        variant="h2"
        sx={{ padding: '.5rem 1.5rem' }}
        fontWeight="medium"
        textTransform="capitalize"
      >
        Tech Intervention
      </MDTypography>
      <Card sx={{ marginTop: '5rem' }}>
        <MDTypography sx={{ padding: '1.5rem', fontSize: '.9rem' }}>
          Tech Intervention è un gestionale per l&apos;amministrazione delle commesse e interventi
          tecnici creato da{' '}
          <Link href={'https://www.stefanofrasca.com'} target={'_blank'}>
            <strong>Stefano Frasca</strong>
          </Link>
          .<br />
          Realizzato andando prima a verificare i requisiti tecnici necessari, dopodichè
          implementando lo schema delle relazioni tra le entità e successivamente implementando la
          logica applicativa tramite un backend realizzato in <strong>Laravel (Lumen)</strong> e un
          frontend in <strong>React</strong> su base MUI, utilizza alcune particolarità nella
          creazione di gestionali e applicativi web:
        </MDTypography>
        <MDTypography variant="ul" sx={{ padding: '0.3rem 1.5rem', fontSize: '.9rem' }}>
          <li>Architettura a microservizi con API Rest per le entità</li>
          <li>Frontend disaccoppiato dal backend (API Rest)</li>
          <li>
            <strong>No architettura MONOLITICA</strong>: è possibile implementare un diverso
            frontend (visual) continuando ad utilizzare le stesse API
          </li>
        </MDTypography>
        <MDTypography sx={{ padding: '0.5rem 1.5rem', fontSize: '.9rem' }} variant="h3">
          Versione: {process.env.REACT_APP_VERSION}
        </MDTypography>
        <MDTypography sx={{ padding: '0.5rem 1.5rem', fontSize: '.9rem' }} variant="h3">
          Last Update: {process.env.REACT_APP_LASTUPDATE}
        </MDTypography>
        <MDBox sx={{ padding: '.5rem 1.5rem' }}>
          <CreditProgress
            color="success"
            value="100"
            text={{
              title: 'DB Structure',
              subtitle: 'Strutturazione DB entity/relationship',
            }}
          />
          <CreditProgress
            color="success"
            value="100"
            text={{
              title: 'ACL',
              subtitle:
                'Gestione controllo accessi con ruoli differenziali: VIEWER, EDITOR, ADMIN per API e risorse Frontend',
            }}
          />
          <CreditProgress
            color="success"
            value="100"
            text={{
              title: 'User',
              subtitle: 'CRUD per gestione utenti',
            }}
          />
          <CreditProgress
            color="success"
            value="100"
            text={{
              title: 'Lookup',
              subtitle: 'CRUD e gestione lookup (tabelle di raccordo)',
            }}
          />
          <CreditProgress
            color="success"
            value="100"
            text={{
              title: 'Intervento Tecnico',
              subtitle: 'CRUD e gestione intervento tecnico',
            }}
          />
          <CreditProgress
            color="success"
            value="100"
            text={{
              title: 'Mini CRUD lookup',
              subtitle:
                'In intervento tecnico, gestione correlati, possibilità di inserire una lookup se non presente (senza andare nel relativo CRUD)',
            }}
          />
          <CreditProgress
            color="success"
            value="100"
            text={{
              title: 'Files',
              subtitle: "Gestione files e storage per allegati dell'intervento tecnico",
            }}
          />
          <CreditProgress
            color="success"
            value="100"
            text={{
              title: 'Ricerca',
              subtitle: 'Form di ricerca degli interventi tecnici tramite parametri',
            }}
          />
          <CreditProgress
            color="success"
            value="100"
            text={{
              title: 'Dashboard',
              subtitle: 'Dashboard grafica funzionale per prima overview dei dati inseriti',
            }}
          />
          <CreditProgress
            color="success"
            value="95"
            text={{
              title: 'Log',
              subtitle:
                "Sistema di logging avanzato per l'analisi delle problematiche da parte degli ADMIN",
            }}
          />
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Credits;
