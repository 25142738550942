import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { JsonView, darkStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';
import MDTypography from 'components/MDTypography';
import { ItemIdentified } from 'components/ItemIdentified';
import DataTable from 'components/Tables/DataTable';
import { formatDate } from '../lib/utils';

function LogDataTable({ getData, reupdate, onupdate }) {
  const [data, setData] = useState({ rows: [], columns: [], fetching: true });

  const mappingLevel = (l) => {
    const map = {
      CRITICAL: 'error',
      ALERT: 'error',
      EMERGENCY: 'error',
      WARNING: 'warning',
      NOTICE: 'dark',
      INFO: 'info',
      DEBUG: 'secondary',
    };
    return map[l];
  };

  /* eslint-disable react/prop-types */
  const columns = [
    {
      Header: 'Elemento',
      accessor: (row) => {
        const { log, ip } = row.element;
        return `${log} (${ip})`;
      },
      Cell: ({ cell }) => {
        const { id, log, ip } = cell.row.original.element;
        return <ItemIdentified id={`${id}`} name={`${log} (${ip})`} />;
      },
      width: '35%',
      align: 'left',
    },
    {
      Header: 'Level',
      accessor: (row) => {
        return row.level;
      },
      Cell: ({ cell }) => {
        return (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={`${cell.value}`}
              color={mappingLevel(`${cell.value}`)}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        );
      },
      align: 'center',
    },
    {
      Header: 'Action Da/Data',
      accessor: (row) => {
        const { date, name } = row.date;
        return `${name} ${date}`;
      },
      Cell: ({ cell }) => {
        const { date, name } = cell.row.original.date;
        return <ItemIdentified id={`${date}`} name={`${name}`} />;
      },
      align: 'left',
    },
    { Header: 'Extra', accessor: 'extra', align: 'left' },
  ];
  /* eslint-enable react/prop-types */

  const decorateFrontend = (dbData) => {
    if (!dbData || dbData.length <= 0) {
      return [];
    }

    const toFe = dbData.map((el) => {
      const who = el?.context?.extra?.user ?? 'N.A.';
      return {
        element: { id: el.id, log: el.message, ip: el.remote_addr },
        level: el.level_name,
        date: { date: formatDate(el.record_datetime), name: who },
        extra: (
          <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
            <JsonView data={el.context} shouldInitiallyExpand={() => false} style={darkStyles} />
          </MDTypography>
        ),
      };
    });

    return toFe;
  };

  useEffect(() => {
    try {
      if (reupdate !== '') {
        getData().then((reqData) => {
          !!onupdate && typeof onupdate === 'function' && onupdate(reqData);
          const mapped = decorateFrontend(reqData.logs);
          setData({
            columns,
            rows: mapped,
            fetching: false,
          });
        });
      }
    } catch (e) {
      console.error(`getData: error on call: ${e.message()}`);
    }
    return () => console.log('ClientDataTable unmounting...');
  }, [reupdate]);

  return (
    <DataTable
      table={{ columns: data.columns, rows: data.rows }}
      isSorted
      canSearch
      entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15] }}
      showTotalEntries
      noEndBorder
      loading={data.rows.length <= 0 && !!data.fetching}
    />
  );
}

LogDataTable.defaultProps = {
  getData: () => {},
  reupdate: 'initial',
  onupdate: () => {},
};

LogDataTable.propTypes = {
  getData: PropTypes.func,
  reupdate: PropTypes.string,
  onupdate: PropTypes.func,
};

export default LogDataTable;
