/**
 =========================================================
 * Material Dashboard 2 React - v2.0.0
 =========================================================

 * Skeleton by: www.creative-tim.com

 Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect } from 'react';

// react-router components
import { useLocation, Link, useNavigate, useSearchParams } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @material-ui core components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';

// Material Dashboard 2 React example components
import Breadcrumbs from 'examples/Breadcrumbs';
import NotificationItem from 'examples/Items/NotificationItem';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from 'examples/Navbars/DashboardNavbar/styles';

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from 'context';
import {
  ROUTE_DASHBOARD,
  ROUTE_LOG,
  ROUTE_LOGIN,
  ROUTE_PROFILE,
  ROUTE_TI_SEARCH,
  STR_LOGOUT_ERROR,
} from 'context/constants';
import { logout } from 'api/auth';
import {
  getLocalStorage,
  LS_AUTH,
  LS_AUTHID,
  LS_AUTHINFO,
  LS_AUTHTYPE,
  removeFromLocalStorage,
} from 'lib/storage';
import AuthVerify from 'components/AuthVerify';
import Divider from '@mui/material/Divider';
import MDBadge from '../../../components/MDBadge';
import MDTypography from '../../../components/MDTypography';
import { isEmpty } from '../../../lib/utils';
import { ADMIN, checkAuth } from '../../../context/auth';

function DashboardNavbar({ absolute, light, isMini }) {
  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
    withSearch,
    withConfigurator,
    withUserProfile,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split('/').slice(1);
  const [infoUser, setInfoUser] = useState({
    name: 'Mr. Robot',
  });
  const [canViewLog, setCanViewLog] = useState(false);
  const [searchParams] = useSearchParams();
  const fromSearch = searchParams.get('from') === 'search';

  useEffect(() => {
    const userInfo = getLocalStorage(LS_AUTHINFO);
    if (!isEmpty(userInfo)) {
      setInfoUser(JSON.parse(userInfo));

      if (checkAuth(ADMIN)) {
        setCanViewLog(true);
      }
    }

    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleLogout = () => {
    logout().then((data) => {
      if (!!data && !!data?.status && data?.status === 'success') {
        removeFromLocalStorage(LS_AUTH);
        removeFromLocalStorage(LS_AUTHTYPE);
        removeFromLocalStorage(LS_AUTHID);
        removeFromLocalStorage(LS_AUTHINFO);
        navigate(ROUTE_LOGIN);
      } else {
        // TODO messaggio di errore logout
        console.warn(STR_LOGOUT_ERROR);
      }
    });
    return true;
  };

  const handleDashboard = () => {
    navigate(ROUTE_DASHBOARD);
  };

  const handleLog = () => {
    navigate(ROUTE_LOG);
  };

  const handleSearch = () => {
    navigate(ROUTE_TI_SEARCH);
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <MDTypography variant="h6" lineHeight={1} sx={{ mt: 0.5, ml: 0.5 }}>
        Ciao {infoUser.nome}
      </MDTypography>
      <Divider />
      <NotificationItem icon={<Icon>logout</Icon>} title="Logout" onClick={handleLogout} />
      <NotificationItem
        icon={<Icon>dashboard</Icon>}
        title="Go To Dashboard"
        onClick={handleDashboard}
      />
      {!!canViewLog && (
        <NotificationItem icon={<Icon>pattern</Icon>} title="View Logs" onClick={handleLog} />
      )}
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {withSearch && (
              <MDBox pr={1}>
                <MDInput label="Search here" />
              </MDBox>
            )}
            <MDBox color={light ? 'white' : 'inherit'}>
              {withUserProfile && (
                <Link to={ROUTE_PROFILE}>
                  <IconButton sx={navbarIconButton} size="small" disableRipple>
                    <Icon sx={iconsStyle}>account_circle</Icon>
                  </IconButton>
                </Link>
              )}
              {withConfigurator && (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? 'menu_open' : 'menu'}
                  </Icon>
                </IconButton>
              )}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      {!!fromSearch && (
        <MDBox ml={1}>
          <MDBadge
            badgeContent={
              <>
                <ArrowBackIosIcon color="light">ArrowBackIosIcon</ArrowBackIosIcon> Search
              </>
            }
            color={'info'}
            variant="gradient"
            size="sm"
            onClick={handleSearch}
          />
        </MDBox>
      )}
      <AuthVerify logOut={handleLogout} />
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
