// ROUTE FRONTEND
export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_LOGIN = '/login';
export const ROUTE_CREDITS = '/credits';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_USERS = '/users';
export const ROUTE_TI_SEARCH = '/search';
export const ROUTE_LOG = '/log';
export const ROUTE_TI_VIEW = '/techintervention';
export const ROUTE_TI_SHOW = '/techintervention/:idParams/:paramsAction';
export const ROUTE_TI_EDIT = '/techintervention/:idParams/:paramsAction';
export const ROUTE_LOOKUP = '/lookup';

export const ROUTE_CLIENTI = '/clienti';
export const ROUTE_APPARECCHIATURE = '/apparecchiature';
export const ROUTE_CERTIFICAZIONI = '/certificazioni';
export const ROUTE_RICHIEDENTI = '/appaltatori';
export const ROUTE_TIPILAVORO = '/tipilavoro';
export const ROUTE_MATERIALI = '/materiali';
export const ROUTE_MODELLI = '/modelli';
export const ROUTE_COSTRUTTORI = '/costruttori';
export const ROUTE_ATTIVITA = '/attivita';
export const ROUTE_PARTI = '/parti';

//CRUD ACTION
export const INSERISCI = 'insert';
export const AGGIORNA = 'edit';
export const RIMUOVI = 'delete';
export const VEDI = 'show';

export const STR_LOGOUT_ERROR = 'Logout non possibile...';

export const SHOW_TOTAL_IN_INFO_PANEL = false;
