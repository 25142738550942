import { getLocalStorage, LS_AUTH } from '../lib/storage';

export const AUTH = 'auth';
export const DASHBOARD = 'dashboard';
export const INTERVENTO = 'intervento';
export const INTERVENTO_CORRELATI = 'correlati';
export const INTERVENTO_DATI = 'dati';
export const USER = 'utente';
export const USERS = 'utenti';
export const CLIENTI = 'clienti';
export const CLIENTE = 'cliente';
export const APPARECCHIATURE = 'apparecchiature';
export const APPARECCHIATURA = 'apparecchiatura';
export const CERTIFICAZIONI = 'certificazioni';
export const CERTIFICAZIONE = 'certificazione';
export const RICHIEDENTI = 'richiedenti';
export const RICHIEDENTE = 'richiedente';
export const TIPILAVORI = 'tipilavori';
export const TIPILAVORO = 'tipilavoro';
export const MATERIALI = 'materiali';
export const MATERIALE = 'materiale';
export const MODELLI = 'modelli';
export const MODELLO = 'modello';
export const COSTRUTTORI = 'costruttori';
export const COSTRUTTORE = 'costruttore';
export const ATTIVITA = 'attivita';
export const PARTI = 'parti';
export const ATTIVITA_IT = 'attivitait';
export const PARTI_IT = 'partiit';
export const SEARCH = 'search';
export const LOG = 'log';
export const LASTLOG = 'lastLog';

export const getBaseApi = (type = 'auth') => {
  const map = {
    auth: `${AUTH}/`,
    dashboard: `${DASHBOARD}/data`,
    search: `${SEARCH}`,
    utente: `${USER}`,
    utenti: `${USERS}`,
    cliente: `${CLIENTE}`,
    clienti: `${CLIENTI}`,
    apparecchiatura: `${APPARECCHIATURA}`,
    apparecchiature: `${APPARECCHIATURE}`,
    certificazioni: `${CERTIFICAZIONI}`,
    certificazione: `${CERTIFICAZIONE}`,
    richiedenti: `${RICHIEDENTI}`,
    richiedente: `${RICHIEDENTE}`,
    tipilavori: `${TIPILAVORI}`,
    tipilavoro: `${TIPILAVORO}`,
    materiali: `${MATERIALI}`,
    materiale: `${MATERIALE}`,
    modelli: `${MODELLI}`,
    modello: `${MODELLO}`,
    costruttori: `${COSTRUTTORI}`,
    costruttore: `${COSTRUTTORE}`,
    attivita: `${ATTIVITA}`,
    parti: `${PARTI}`,
    intervento: `${INTERVENTO}`,
    correlati: `${INTERVENTO}/${INTERVENTO_CORRELATI}`,
    dati: `${INTERVENTO}/${INTERVENTO_DATI}`,
    attivitait: `${INTERVENTO}/${ATTIVITA}`,
    partiit: `${INTERVENTO}/${PARTI}`,
    lastLog: `${LOG}/data`,
  };

  return process.env.REACT_APP_PUBLIC_URL_API + (map[type] || 'auth');
};

const getBearer = () => `Bearer ${getLocalStorage(LS_AUTH)}`;

export async function postData(url = '', data = {}) {
  const bearer = getBearer();
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearer,
      'X-API-Generator': 'fefoweb/TechIntervention',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function postDataFile(url = '', data = {}) {
  const bearer = getBearer();
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  //---- https://stackoverflow.com/questions/47676134/laravel-request-all-is-empty-using-multipart-form-data + without Content-Type
  formData.append('_method', 'PUT'); //'Content-Type': 'multipart/form-data',
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      Authorization: bearer,
      'X-API-Generator': 'fefoweb/TechIntervention',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: formData, // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function putData(url = '', data = {}) {
  const bearer = getBearer();
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearer,
      'X-API-Generator': 'fefoweb/TechIntervention',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

/*export async function putDataFile(url = '', data = {}) {
  const bearer = getBearer();
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  //---- https://stackoverflow.com/questions/47676134/laravel-request-all-is-empty-using-multipart-form-data + without Content-Type
  formData.append('_method', 'PUT'); //'Content-Type': 'multipart/form-data',
  // Default options are marked with //*
  const response = await fetch(url, {
    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      //'Content-Type': 'multipart/form-data',
      Authorization: bearer,
      'X-API-Generator': 'fefoweb/TechIntervention',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: formData, // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}*/

export async function getData(url = '') {
  const bearer = getBearer();
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearer,
      'X-API-Generator': 'fefoweb/TechIntervention',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function deleteData(url = '') {
  const bearer = getBearer();
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearer,
      'X-API-Generator': 'fefoweb/TechIntervention',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
  return response.json(); // parses JSON response into native JavaScript objects
}
