/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Skeleton by: www.creative-tim.com

Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react';

// @mui material components
import Card from '@mui/material/Card';

import { useNavigate } from 'react-router-dom';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// Images
import bgImage from 'assets/images/bg-hi-tech.jpeg';
import { login } from 'api/auth';
import {
  getLocalStorage,
  LS_AUTH,
  LS_AUTHID,
  LS_AUTHINFO,
  LS_AUTHTYPE,
  setLocalStorage,
} from 'lib/storage';
import { ROUTE_DASHBOARD } from 'context/constants';

function Basic() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const token = getLocalStorage(LS_AUTH);
  useEffect(() => {
    if (!!token) {
      navigate(ROUTE_DASHBOARD);
    }
  }, [token]);

  const handleSetEmail = (e) => {
    return setEmail(e.target.value);
  };

  const handleSetPassword = (e) => {
    return setPassword(e.target.value);
  };

  const handleSendEmail = (e) => {
    const subjectEncode = encodeURIComponent(`Creazione account per ${email}`);
    const bodyEncode = encodeURIComponent(
      `Si richiede la creazione di un account per username ${email}.`
    );
    window.location = `mailto:admin@frigoelettrica.com?subject=${subjectEncode}&body=${bodyEncode}`;
    e.preventDefault();
  };

  const handleLogin = () => {
    login(email, password).then((data) => {
      !!data?.token && setLocalStorage(LS_AUTH, data.token);
      !!data?.type && setLocalStorage(LS_AUTHTYPE, data.type);
      !!data?.id && setLocalStorage(LS_AUTHID, data.id);
      (!!data?.name || !!data?.email) &&
        setLocalStorage(
          LS_AUTHINFO,
          JSON.stringify({
            nome: data?.name || 'User',
            email: data?.email || '-',
          })
        );
      if (!data || !data.token) {
        console.error('Errore al login...');
        return false;
      }
      navigate(ROUTE_DASHBOARD);
      return true;
    });
    return true;
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Log In
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={email}
                onChange={handleSetEmail}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={handleSetPassword}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleLogin}>
                Log In
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Non hai un account?{' '}
                <MDButton
                  color="dark"
                  variant="gradient"
                  onClick={handleSendEmail}
                  disabled={false}
                >
                  Chiedilo!
                </MDButton>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
