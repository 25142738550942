/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { formatDate } from 'lib/utils';
import MDBox from 'components/MDBox';
//import MDBadge from 'components/MDBadge';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDAvatar from 'components/MDAvatar';
import MDProgress from 'components/MDProgress';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ItemIdentified } from 'components/ItemIdentified';
import DataTable from 'components/Tables/DataTable';
import team1 from 'assets/images/users_bn.png';
import { ADMIN, checkAuth } from 'context/auth';
import { AGGIORNA, RIMUOVI, VEDI } from 'context/constants';

import pxToRem from '../assets/theme-dark/functions/pxToRem';

function TechInterventionTable({ getData, onEditClb, onDeleteClb, onShowClb, reupdate, onupdate }) {
  const [data, setData] = useState({ rows: [], columns: [], fetching: true });
  const deletePermission = ADMIN;

  const avatars = (members) =>
    members.map(([image, name, id]) => (
      <Tooltip
        key={`${id}-${name}`}
        title={!!name && name !== 'null' ? name : '-'}
        placeholder="bottom"
      >
        <MDAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: 'none',
            cursor: 'pointer',
            position: 'relative',

            '&:not(:first-of-type)': {
              ml: -1.25,
            },

            '&:hover, &:focus': {
              zIndex: '10',
            },
          }}
        />
      </Tooltip>
    ));

  const columns = [
    { Header: 'Intervento', accessor: 'intervento', width: '45%', align: 'left' },
    { Header: 'Tecnico', accessor: 'tecnico', width: '10%', align: 'left' },
    { Header: 'Data', accessor: 'data', align: 'center' },
    { Header: 'Timing', accessor: 'time', align: 'center' },
    { Header: 'Action', accessor: 'action', align: 'center' },
  ];

  const onEdit = (event) => {
    event.stopPropagation();
    let { id } = event.target.dataset;
    if (!id) {
      id = event.target.parentElement.dataset.id;
    }
    !!onEditClb && 'function' === typeof onEditClb && onEditClb(id, AGGIORNA);
    //console.log('EDITO ELEMENTO:', id);
  };
  const onShow = (event) => {
    event.stopPropagation();
    let { id } = event.target.dataset;
    if (!id) {
      id = event.target.parentElement.dataset.id;
    }
    !!onShowClb && 'function' === typeof onShowClb && onShowClb(id, VEDI);
    //console.log('EDITO ELEMENTO:', id);
  };
  const onDelete = (event) => {
    event.stopPropagation();
    let { id } = event.target.dataset;
    if (!id) {
      id = event.target.parentElement.dataset.id;
    }
    !!onDeleteClb && 'function' === typeof onDeleteClb && onDeleteClb(id, RIMUOVI);
    //console.log('RIMUOVO ELEMENTO:', id);
  };

  const decorateFrontend = (dbData) => {
    if (!dbData || dbData.length <= 0) {
      return [];
    }
    const totaleOre = dbData.reduce((acc, ti) => acc + ti.oreLavorate, 0);

    const toFe = dbData.map((el) => {
      const perc = Math.ceil((el.oreLavorate * 100) / totaleOre);
      const color = perc < 30 ? 'error' : perc < 60 ? 'warning' : 'success';
      return {
        intervento: <ItemIdentified id={el.id} name={el.matricola} />,
        tecnico: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox ml={2} lineHeight={1}>
              <MDTypography display="block" variant="button" fontWeight="medium">
                {avatars([[team1, el.tecnicoEsecutore, el.id]])}
              </MDTypography>
              {!!el.tecnicoEsecutore && el.tecnicoEsecutore !== 'null' && (
                <MDTypography
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  sx={{ lineHeight: pxToRem(25) }}
                >
                  {el.tecnicoEsecutore}
                </MDTypography>
              )}
            </MDBox>
          </MDBox>
        ),
        data: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {!!el.dataIntervento ? formatDate(el.dataIntervento, true) : '-'}
          </MDTypography>
        ),
        time: (
          <MDBox width="8rem" textAlign="center">
            <MDProgress value={perc} color={color} variant="gradient" label={false} />
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {!!el.oreLavorate ? el.oreLavorate : '-'} h
            </MDTypography>
          </MDBox>
        ),
        action: (
          <>
            <MDButton
              sx={{ m: 1 }}
              variant="gradient"
              color="success"
              onClick={onShow}
              data-id={el.id}
            >
              <VisibilityIcon />
            </MDButton>
            <MDButton
              sx={{ m: 1 }}
              variant="gradient"
              color="warning"
              onClick={onEdit}
              data-id={el.id}
            >
              <AppRegistrationIcon />
            </MDButton>
            {!!checkAuth(deletePermission) && (
              <MDButton
                sx={{ m: 1 }}
                variant="gradient"
                color="error"
                onClick={onDelete}
                data-id={el.id}
              >
                <DeleteForeverIcon />
              </MDButton>
            )}
          </>
        ),
      };
    });

    return toFe;
  };

  useEffect(() => {
    try {
      if (reupdate !== '') {
        getData().then((reqData) => {
          //TODO: magari spostare e centralizzare il controllo dell'Unauthorized
          if (!!reqData.status_code && 401 === +reqData.status_code) {
            return;
          }
          !!onupdate && typeof onupdate === 'function' && onupdate(reqData);
          const mapped = decorateFrontend(reqData);
          setData({
            columns,
            rows: mapped,
            fetching: false,
          });
        });
      }
    } catch (e) {
      console.error(`getData: error on call: ${e.message()}`);
    }
    return () => console.log('TechIntervention unmounting...');
  }, [reupdate]);

  return (
    <DataTable
      table={{ columns: data.columns, rows: data.rows }}
      isSorted
      canSearch={false}
      entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15] }}
      showTotalEntries
      noEndBorder
      loading={data.rows.length <= 0 && !!data.fetching}
    />
  );
}

TechInterventionTable.defaultProps = {
  getData: () => {},
  onEditClb: () => {},
  onDeleteClb: () => {},
  onShowClb: () => {},
  reupdate: 'initial',
  onupdate: () => {},
};

TechInterventionTable.propTypes = {
  getData: PropTypes.func,
  onEditClb: PropTypes.func,
  onDeleteClb: PropTypes.func,
  onShowClb: PropTypes.func,
  reupdate: PropTypes.string,
  onupdate: PropTypes.func,
};

export default TechInterventionTable;
