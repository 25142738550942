/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Skeleton by: www.creative-tim.com

Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

import EngineeringIcon from '@mui/icons-material/Engineering';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';

// Material Dashboard 2 PRO React base styles
import breakpoints from 'assets/theme/base/breakpoints';

// Images
import documentIcon from 'assets/images/document.webp';
import backgroundImage from 'assets/images/bg-hi-tech3.jpg';

function Header({ children, setTab, tabValue, title, techIntervention, displayAction }) {
  const [tabsOrientation, setTabsOrientation] = useState('horizontal');

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTab(newValue);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: '50%',
          overflow: 'hidden',
        }}
      />
      <Card
        sx={{
          position: 'relative',
          mt: -12,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} md={6} lg={6} sx={{ ml: 'auto' }}>
            <Grid container spacing={0} alignItems="center">
              <MDAvatar src={documentIcon} alt="profile-image" size="xl" shadow="sm" />
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {title}
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {displayAction === 'show'
                    ? "Verifica le informazioni dell'intervento tecnico"
                    : 'Aggiungi le informazioni tramite gli step'}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid item xs={6} md={6} lg={6} sx={{ ml: 'auto' }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Info"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      home
                    </Icon>
                  }
                />
                <Tab label="Correlati" icon={<EngineeringIcon />} />
                <Tab label="Dati" icon={<DriveFileMoveIcon />} />
                <Tab
                  label="Attivita & Parti"
                  disabled={!techIntervention.id}
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      settings
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: '',
  setTab: () => {},
  tabValue: '',
  title: '',
  techIntervention: {},
  displayAction: 'edit',
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
  setTab: PropTypes.func,
  tabValue: PropTypes.number,
  title: PropTypes.string,
  techIntervention: PropTypes.object,
  displayAction: PropTypes.string,
};

export default Header;
