import PropTypes from 'prop-types';

import MDBox from '../MDBox';
import MDTypography from '../MDTypography';

export const ItemIdentified = ({ id, name }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption">{id}</MDTypography>
    </MDBox>
  </MDBox>
);

ItemIdentified.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
