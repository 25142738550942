/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */

import React from 'react';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import PropTypes from 'prop-types';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

// https://stackoverflow.com/questions/61655199/proper-way-to-use-react-hook-form-controller-with-material-ui-autocomplete
// https://codesandbox.io/s/react-hook-form-v7-material-ui-5-lazy-loaded-values-not-validated-in-autocomplete-controller-forked-egcsf?file=/src/App.js:0-3054

export default function RHAutocomplete({
  control,
  autocompleteOptions,
  formName = 'item',
  label = 'Item',
  defaultValue,
  errors,
  rules,
}) {
  const classes = useStyles();
  return (
    <Controller
      render={({ field: { onChange } }) => (
        <Autocomplete
          autoComplete
          includeInputInList
          id={`autocomplete_${formName}`}
          options={autocompleteOptions}
          classes={{
            option: classes.option,
          }}
          value={defaultValue}
          onChange={(event, item) => {
            onChange(item);
          }}
          isOptionEqualToValue={(option, val) =>
            val === undefined || val === '' || option.id === val.id
          }
          clearOnBlur={false}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              error={!!errors[formName]}
              helperText={errors[formName]?.message}
            />
          )}
        />
      )}
      rules={rules}
      name={formName}
      control={control}
    />
  );
}

RHAutocomplete.defaultProps = {
  formName: 'item',
  label: 'Item',
  errors: {},
  rules: {},
  defaultValue: '',
};

RHAutocomplete.propTypes = {
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.object,
  autocompleteOptions: PropTypes.any.isRequired,
  formName: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.object,
  rules: PropTypes.object,
};
