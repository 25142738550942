/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import themeDark from 'assets/theme-dark';
import {
  Autocomplete,
  CircularProgress,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MDInput from 'components/MDInput';
import colors from 'assets/theme-dark/base/colors';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { getLookupSelectValueApiFromKeyDb } from 'reducer/api/mappingApi';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    '& .MuiFormHelperText-root': {
      color: colors.badgeColors.warning.background,
    },
  },
});

// https://stackoverflow.com/questions/61655199/proper-way-to-use-react-hook-form-controller-with-material-ui-autocomplete
// https://codesandbox.io/s/react-hook-form-v7-material-ui-5-lazy-loaded-values-not-validated-in-autocomplete-controller-forked-egcsf?file=/src/App.js:0-3054

const filter = createFilterOptions();

export default function RHDAutocomplete({
  control,
  callingApi,
  insertApi,
  fnOnChange,
  watch,
  formValue,
  formName = 'item',
  label = 'Item',
  errors,
  rules,
  defaultDialog,
  prepopulateKey,
  disabled,
}) {
  const classes = useStyles();
  const withInsert = 'undefined' !== typeof insertApi;
  const aKey = !!withInsert ? Object.keys(defaultDialog) : [];

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  const [withData, setWithData] = useState(true);

  const [autocompleteValue, setAutocompleteValue] = useState(null);

  /*********     MODALE DI INSERIMENTO ********************/
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogValue, setDialogValue] = useState(defaultDialog ?? {});
  let handleClose;
  let handleSubmit;
  const formDialogElement = [];
  let populateDialogValue;

  useEffect(() => {
    //TODO DA OTTIMIZZARE.....
    const valueSelect = watch(formName) || null;
    const $fnCallback = getLookupSelectValueApiFromKeyDb(formName);
    const id = !!valueSelect?.id ? valueSelect.id : valueSelect;

    if (null !== id) {
      setWithData(false);
      $fnCallback(id).then((data) => {
        if (!!data.id) {
          setWithData(true);
          setAutocompleteValue(data);
        }
      });
    }
  }, [formName]);

  // LOGICA DELLA MODALE DI INSERIMENTO... todo: sistemare
  if (withInsert) {
    const createFormDialogElement = () => {
      aKey.forEach((key) => {
        // placeholder => 'attivo' === key ? 'Aggiungi SI o NO (oppure vuoto)' : 'Inserisci valore'
        formDialogElement.push(
          <Grid item xs={12} md={6} key={`grid_${key}`}>
            <TextField
              autoFocus
              margin="dense"
              id={key}
              value={dialogValue[key]}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  [key]: event.target.value,
                })
              }
              label={defaultDialog[key].label}
              placeholder={'Inserisci valore'}
              type="text"
              variant="standard"
              fullWidth
              inputProps={{
                maxLength: defaultDialog[key].maxLength,
              }}
              helperText={`Inserire testo di max ${defaultDialog[key].maxLength} car.`}
              className={classes.textField}
            />
          </Grid>
        );
      });
    };
    createFormDialogElement();

    populateDialogValue = (text, keyToPrepopulate = prepopulateKey) => {
      const mapped = {};
      aKey.forEach((key) => {
        mapped[key] = '';
      });
      mapped[keyToPrepopulate] = text;

      return mapped;
    };

    handleClose = () => {
      setDialogValue(defaultDialog ?? {});

      setOpenDialog(false);
    };

    const mappingDialogValue = () => {
      const mapped = {};
      aKey.forEach((key) => {
        if (defaultDialog[key].type === 'string') {
          mapped[key] = dialogValue[key];
        } else if (defaultDialog[key].type === 'bool') {
          // TODO SISTEMARE... magari con bool => checkbox
          mapped[key] = dialogValue[key]?.toLowerCase() === 'si' ? 1 : 0;
        }
      });

      return mapped;
    };

    handleSubmit = (event) => {
      event.preventDefault();
      const toSave = mappingDialogValue();
      const $fnCallback = getLookupSelectValueApiFromKeyDb(formName);
      setWithData(false);
      //save to DB
      'function' === typeof insertApi &&
        insertApi(toSave).then((data) => {
          if (!!data.id) {
            $fnCallback(data.id).then((optionData) => {
              if (!!optionData.id) {
                //console.log('-------####', optionData);
                //manage autocomplete
                setAutocompleteValue(optionData);
                setWithData(true);
                formValue(formName, optionData);
              }
            });

            handleClose();
          } else {
            handleClose();
          }
        });
    };
  }
  /************************** ******************************/

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const data = await callingApi();

      if (active) {
        setOptions([...data]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Controller
      key={`controller_${formName}`}
      render={({ field: { onChange } }) => (
        <>
          {!withData && (
            <CircularProgress key={`loading_auto_${formName}`} color="secondary" size={20} />
          )}
          {!!withData && (
            <Autocomplete
              disabled={disabled}
              key={`autocomplete_${formName}`}
              autoComplete
              includeInputInList
              selectOnFocus
              handleHomeEndKeys
              freeSolo
              value={autocompleteValue}
              open={open}
              id={`autocomplete_${formName}`}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              options={options}
              loading={loading}
              classes={{
                option: classes.option,
              }}
              onChange={(event, item) => {
                //onChange(item);
                if (typeof item === 'string') {
                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    setOpenDialog(true);
                    setDialogValue(populateDialogValue(item));
                  });
                } else if (item && item.inputValue) {
                  setOpenDialog(true);
                  setDialogValue(populateDialogValue(item.inputValue));
                } else {
                  onChange(item);

                  if (fnOnChange !== null && typeof fnOnChange === 'function') {
                    fnOnChange(item);
                  }
                }
              }}
              filterOptions={(opt, params) => {
                const filtered = filter(opt, params);
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    label: `Add "${params.inputValue}"`,
                    key: `opt_${params.inputValue}`,
                  });
                }

                return filtered;
              }}
              isOptionEqualToValue={(option, val) =>
                val === undefined || val === '' || option.id === val.id
              }
              getOptionLabel={(option) => {
                // e.g value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return `Add "${option.inputValue}"`;
                }
                return option.label;
              }}
              renderInput={(params) => (
                <MDInput
                  key={`autocompleteinput_${formName}`}
                  type="text"
                  label={label}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="primary" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  error={!!errors[formName]}
                  helperText={errors[formName]?.message}
                  {...params}
                />
              )}
            />
          )}
          {withInsert && (
            <Dialog
              key={`dialog_autocomplete_${formName}`}
              open={openDialog}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: '80%',
                  backgroundColor: themeDark.palette.background.default,
                  color: 'black',
                },
              }}
            >
              <form onSubmit={handleSubmit} key={`form_da_${formName}`}>
                <DialogTitle key={`dtitle_da_${formName}`}>Aggiungi elemento</DialogTitle>
                <DialogContent key={`dcontent_da_${formName}`}>
                  <DialogContentText>
                    Manca un elemento nella lista? Puoi aggiungerlo!
                  </DialogContentText>
                  <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
                    {formDialogElement}
                  </Grid>
                </DialogContent>
                <DialogActions key={`daction_da_${formName}`}>
                  <Button onClick={handleClose}>Cancella</Button>
                  <Button type="submit">Aggiungi</Button>
                </DialogActions>
              </form>
            </Dialog>
          )}
        </>
      )}
      rules={rules}
      name={formName}
      control={control}
    />
  );
}

RHDAutocomplete.defaultProps = {
  formName: 'item',
  label: 'Item',
  errors: {},
  rules: {},
  insertApi: null,
  defaultDialog: { nome: 'string' },
  prepopulateKey: 'nome',
  disabled: false,
  fnOnChange: null,
};

RHDAutocomplete.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  formValue: PropTypes.func.isRequired,
  callingApi: PropTypes.func.isRequired,
  fnOnChange: PropTypes.func,
  insertApi: PropTypes.func,
  formName: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.object,
  rules: PropTypes.object,
  defaultDialog: PropTypes.object,
  prepopulateKey: PropTypes.string,
  disabled: PropTypes.bool,
};
