export const LS_AUTH = 'ti:auth';
export const LS_AUTHTYPE = 'ti:authtype';
export const LS_AUTHID = 'ti:authid';
export const LS_AUTHINFO = 'ti:authinfo';
export const LS_SEARCH_PARAMS = 'ti:searchparams';

// TODO: polyfill
const __storage = window.localStorage || {};

export const setLocalStorage = (key, value) => {
  __storage.setItem(key, value);
};
export const getLocalStorage = (key) => __storage.getItem(key);

export const removeFromLocalStorage = (key) => {
  __storage.removeItem(key);
};
export const clearLocalStorage = () => {
  __storage.clear();
};
