/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Skeleton by: www.creative-tim.com

Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  The rgba() function helps you to create a rgba color code, it uses the hexToRgb() function
  to convert the hex code into rgb for using it inside the rgba color format.
 */

// Material Dashboard 2 PRO React helper functions
import hexToRgb from 'assets/theme-dark/functions/hexToRgb';

function rgba(color, opacity) {
  return `rgba(${hexToRgb(color)}, ${opacity})`;
}

export default rgba;
