import Grid from '@mui/material/Grid';

import { AttivitaTi } from '../form/Attivita';
import { PartiTi } from '../form/Parti';

const AttivitaPartiPage = (props) => {
  return (
    <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
      <Grid item xs={12} md={12}>
        <AttivitaTi {...props} />
      </Grid>
      <Grid item xs={12} md={12}>
        <PartiTi {...props} />
      </Grid>
    </Grid>
  );
};

export default AttivitaPartiPage;
