import { getLocalStorage, LS_AUTHTYPE } from '../lib/storage';

export const ADMIN = 8;
export const EDITOR = 4;
export const VIEWER = 2;

export const isAdmin = () => {
  const userType = getLocalStorage(LS_AUTHTYPE);
  return !!userType && 8 === +userType;
};

export const isEditor = () => {
  const userType = getLocalStorage(LS_AUTHTYPE);
  return !!userType && 4 === +userType;
};

export const isViewer = () => {
  const userType = getLocalStorage(LS_AUTHTYPE);
  return !!userType && 2 === +userType;
};

export const checkAuth = (check) => {
  if (check === ADMIN) {
    return isAdmin();
  }
  if (check === EDITOR) {
    return isAdmin() || isEditor();
  }
  if (check === VIEWER) {
    return isAdmin() || isEditor() || isViewer();
  }
  return true;
};
