/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Skeleton by: www.creative-tim.com

Heavly modified by www.stefanofrasca.com | @fefoweb

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useNavigate } from 'react-router-dom';
// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import {
  ROUTE_APPARECCHIATURE,
  ROUTE_ATTIVITA,
  ROUTE_CERTIFICAZIONI,
  ROUTE_CLIENTI,
  ROUTE_COSTRUTTORI,
  ROUTE_MATERIALI,
  ROUTE_MODELLI,
  ROUTE_PARTI,
  ROUTE_RICHIEDENTI,
  ROUTE_TIPILAVORO,
} from 'context/constants';
import { goToRoute } from '../../lib/utils';

function Lookup() {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox p={2} lineHeight={0}>
                <MDTypography variant="h3">Lookup</MDTypography>
                <MDTypography variant="h6">
                  Dati di utilità da inserire in modo `non continuativo`
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      data-route={ROUTE_CLIENTI}
                      onClick={(e) => goToRoute(e, navigate)}
                      fullWidth
                    >
                      Clienti
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      data-route={ROUTE_RICHIEDENTI}
                      onClick={(e) => goToRoute(e, navigate)}
                      fullWidth
                    >
                      Appaltatori
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={(e) => goToRoute(e, navigate)}
                      data-route={ROUTE_TIPILAVORO}
                      fullWidth
                    >
                      Tipi di Lavoro
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={(e) => goToRoute(e, navigate)}
                      data-route={ROUTE_APPARECCHIATURE}
                      fullWidth
                    >
                      Apparecchiature
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={(e) => goToRoute(e, navigate)}
                      data-route={ROUTE_COSTRUTTORI}
                      fullWidth
                    >
                      Costruttori
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={(e) => goToRoute(e, navigate)}
                      data-route={ROUTE_MATERIALI}
                      fullWidth
                    >
                      Materiali
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={(e) => goToRoute(e, navigate)}
                      data-route={ROUTE_MODELLI}
                      fullWidth
                    >
                      Modelli
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={(e) => goToRoute(e, navigate)}
                      data-route={ROUTE_CERTIFICAZIONI}
                      fullWidth
                    >
                      Certificazioni
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton
                      variant="gradient"
                      color="warning"
                      onClick={(e) => goToRoute(e, navigate)}
                      data-route={ROUTE_ATTIVITA}
                      fullWidth
                    >
                      Attività
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton
                      variant="gradient"
                      color="warning"
                      onClick={(e) => goToRoute(e, navigate)}
                      data-route={ROUTE_PARTI}
                      fullWidth
                    >
                      Parti
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Lookup;
