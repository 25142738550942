import PropTypes from 'prop-types';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Checkbox from '@mui/material/Checkbox';

import { INSERISCI } from 'context/constants';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import Card from '@mui/material/Card';

export const LookupForm = ({ value, handle, formError, action }) => (
  <Card variant="outlined">
    <MDBox pt={4} pb={3} px={3}>
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            type="text"
            label="Label"
            variant="standard"
            fullWidth
            onChange={handle.handleSetNome}
            error={formError.name}
            value={value.name}
          />
        </MDBox>
        <MDBox display="flex" alignItems="center" ml={-1}>
          <Checkbox
            onChange={handle.handleSetActive}
            error={formError.active}
            value={value.active}
            checked={value.active}
          />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
          >
            &nbsp;Attiva&nbsp;
          </MDTypography>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton variant="gradient" color="info" fullWidth onClick={handle.actionToLookup}>
            {action === INSERISCI ? 'Inserisci' : 'Aggiorna'}
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  </Card>
);

LookupForm.defaultProps = {
  value: {},
  handle: {
    actionToLookup: () => {},
  },
  formError: {},
};

LookupForm.propTypes = {
  value: PropTypes.any,
  handle: PropTypes.any,
  formError: PropTypes.any,
  action: PropTypes.string.isRequired,
};
