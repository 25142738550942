import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { getLocalStorage, LS_AUTH } from 'lib/storage';
import withRouter from 'lib/withRouter';

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
const AuthVerify = ({ router, logOut }) => {
  useEffect(() => {
    const token = getLocalStorage(LS_AUTH);
    if (!!token) {
      const decodedJwt = parseJwt(token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        logOut();
      }
    }
  }, [router.location]);

  return <></>;
};

AuthVerify.propTypes = {
  logOut: PropTypes.func,
  router: PropTypes.any,
};
AuthVerify.defaultProps = {
  logOut: () => {},
  router: {},
};

export default withRouter(AuthVerify);
